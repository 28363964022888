export default function createRateSubmitData(stateData) {
  const result = [];
  const checkIsRateItemEmpty = (obj, key) => !obj[key].value && !obj[key].message;

  Object.keys(stateData).forEach((key) => {
    if (!checkIsRateItemEmpty(stateData, key)) {
      result.push({
        feedback_message: stateData[key].message,
        feedback_rating: stateData[key].value,
        feedback_category: stateData[key].category,
      });
    }
  });
  return result;
}
