import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Header } from "components/presenters/layouts";
import { SEO } from "configs/seo";
import { ROUTE_PATHS } from "app/providers/router";

import * as Styled from "./Policy.style";

export default function Terms() {
  const history = useHistory();

  function onBackClick() {
    history.goBack();
  }

  return (
    <>
      <SEO title="Terms" />
      <Header />
      <Styled.Wrapper data-testid="terms-page">
        <Styled.Button onClick={onBackClick}>← Back</Styled.Button>
        <Styled.Title>GeoX Web Platform Terms of Use</Styled.Title>
        <Styled.Text>Welcome to GeoX Web Platform! It provides you with easy, online access to our Data.</Styled.Text>
        <Styled.Text>
          These GeoX Web Platform Terms of Use (<strong>GeoX Web Platform Terms</strong>) set out the terms and
          conditions on which you can use GeoX Web Platform and our GeoX Web Platform website located at{" "}
          <a href="https://geoxanalytics.com" target="_blank" rel="noopener noreferrer">
            https://geoxanalytics.com
          </a>{" "}
          (<strong>GeoX Web Platform</strong>).
        </Styled.Text>
        <Styled.Text>These GeoX Web Platform Terms legally bind you where you:</Styled.Text>
        <Styled.List>
          <Styled.ListItem>
            register for a GeoX Web Platform Account and you click the ‘I accept’ button to accept these GeoX Web
            Platform Terms when prompted;
          </Styled.ListItem>
          <Styled.ListItem>access our GeoX Web Platform Website.</Styled.ListItem>
        </Styled.List>
        <Styled.Text>
          You agree that where you register a GeoX Web Platform Account or access the GeoX Web Platform Website, you do
          so on behalf of your organization, unless you are registering such an account or accessing the GeoX Web
          Platform Website as an individual.
        </Styled.Text>
        <Styled.Text>
          If you are using GeoX Web Platform as an employee, officer, agent, contractor or other representative of an
          organization, you warrant to us that you have full legal authority to bind your organization to these GeoX Web
          Platform Terms.
        </Styled.Text>
        <Styled.Text>
          We’ve included a brief explanation of each part of these GeoX Web Platform Terms on the left side to help you
          understand them. However, it’s the words on the right side that legally bind you.
        </Styled.Text>

        <Styled.OrderedList>
          <Styled.ListItem>
            Evaluating our Trial Data
            <Styled.OrderedList>
              <Styled.ListItem>
                From time to time we may make Trial Data available through GeoX Web Platform.
              </Styled.ListItem>
              <Styled.ListItem>
                We grant you a limited, worldwide, non-exclusive, non-sublicensable, non-transferrable license to use
                Trial Data strictly for the purpose of internally evaluating the suitability of GeoX Web Platform and
                the Trial Data for your business needs (the Permitted Purpose) in accordance with these GeoX Web
                Platform Terms.
              </Styled.ListItem>
              <Styled.ListItem>
                You must not commercially exploit Trial Data in any way, including by using it to develop capability
                (including machine learning capability), products, services, outputs or other material (including
                machine learning algorithms) for commercialization or potential commercialization.
              </Styled.ListItem>
              <Styled.ListItem>
                You may download Trial Data that we explicitly make available for download. However, you must not
                otherwise download, extract, scrape or otherwise harvest data from GeoX Web Platform.
              </Styled.ListItem>
              <Styled.ListItem>
                Your license to use Trial Data may be:
                <ol type="a">
                  <Styled.Item>
                    limited to the trial period nominated by us, which we may change by notice to you; and
                  </Styled.Item>
                  <Styled.Item>
                    subject to other restrictions or limitations notified to you by us from time to time.
                  </Styled.Item>
                </ol>
              </Styled.ListItem>
              <Styled.ListItem>
                We make no representations or warranties regarding the accuracy or correctness of the Trial Data, or
                that the Trial Data is suitable for your particular business needs.
              </Styled.ListItem>
              <Styled.ListItem>
                We warrant that we are entitled to grant you the license to use the Trial Data pursuant to clause 1.2
                and that the grant of that license does not infringe the Intellectual Property of any third party.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Authorized Users
            <Styled.OrderedList>
              <Styled.ListItem>
                You may authorise your employees, officers, agents, contractors and other representatives to use your
                GeoX Web Platform Account on your behalf. In these GeoX Web PlatformTerms, they are known as your
                <strong> Authorized Users</strong>.
              </Styled.ListItem>
              <Styled.ListItem>
                You must ensure that each Authorized User complies with all the provisions of these GeoX Web
                PlatformTerms as if they were a party to these GeoX Web PlatformTerms.
              </Styled.ListItem>
              <Styled.ListItem>
                All acts and omissions of an Authorized User are deemed to be your acts and omissions for the purpose of
                these GeoX Web Platform Terms.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            GeoX Web Platform and GeoX Web Platform Terms
            <Styled.OrderedList>
              <Styled.ListItem>
                We may vary the features, functions and other benefits available to you and your Authorized Users as
                part of GeoX Web Platform and the GeoX Web Platform Website from time to time, without any requirement
                to provide prior notice of the variation to you.
              </Styled.ListItem>
              <Styled.ListItem>
                Nothing in these GeoX Web Platform Terms imposes any obligation on us to develop, release or install for
                you any updates, upgrades, patches, bug fixes, new releases or new versions in respect of GeoX Web
                Platform (<strong>Updates</strong>). However, if we do develop or release any Updates, we may require
                that all such Updates be used by you and any Authorized User.
              </Styled.ListItem>
              <Styled.ListItem>
                Subject to clauses 12.1 and 12.2, we make the GeoX Web Platform Website and the information and material
                on the GeoX Web Platform Website available for general informational purposes only and we do not make
                any representations, warranties or guarantees of any kind, express or implied, as to the operation,
                suitability, accuracy or currency of the GeoX Web Platform Website or the information or material
                available on the GeoX Web Platform Website.
              </Styled.ListItem>
              <Styled.ListItem>
                You agree that we may vary these GeoX Web Platform Terms from time to time. Where you hold a GeoX Web
                Platform Account with us, we will provide you with 30 days’ notice of any changes to these GeoX Web
                Platform Terms, and these changes will be deemed to be accepted unless you notify us otherwise within 30
                days of us providing you with notice. If you do not accept the changes to these GeoX Web Platform Terms,
                you may terminate your Subscription Agreement or provide us with notice that your Subscription Agreement
                will expire at the end of the then current Initial Term or Further Term (as these terms are defined in
                your Subscription Agreement).
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Third Party Applications
            <Styled.OrderedList>
              <Styled.ListItem>
                GeoX Web Platform and the GeoX Web Platform Website may interact with Third Party Applications, or
                require Third Party Applications or Third Party Providers be used to provide particular features or
                functionality.
              </Styled.ListItem>
              <Styled.ListItem>
                You acknowledge that these Third Party Applications are subject to Third Party Provider Terms (set out
                in clause 4.4), and you must ensure that your use (including use by any Authorized User) of these Third
                Party Applications complies with the relevant Third Party Provider Terms.
              </Styled.ListItem>
              <Styled.ListItem>
                We do not make any representations or warranties regarding any Third Party Applications.
              </Styled.ListItem>
              <Styled.ListItem>
                GeoX Web Platform uses Third Party Applications which are subject to the following terms (
                <strong>Third Party Provider Terms</strong>):
                <ol type="a">
                  <Styled.Item>
                    Amazon Web Services: <a href="https://aws.amazon.com/legal">https://aws.amazon.com/legal</a>;
                  </Styled.Item>
                  <Styled.Item>Google: </Styled.Item>
                </ol>
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Usernames and passwords
            <Styled.OrderedList>
              <Styled.ListItem>
                You are responsible for maintaining, securing and protecting your username and password to access your
                GeoX Web Platform Account and used only by you or the Authorized User to whom they have been issued.
              </Styled.ListItem>
              <Styled.ListItem>
                You must:
                <ol type="a">
                  <Styled.Item>
                    immediately notify us:
                    <ol type="I">
                      <Styled.Item>
                        if an Authorized User (which includes your employees, agents, officers, contractors or other
                        representatives) ceases to be employed or engaged by you, or is no longer Authorized by you to
                        access GeoX Web Platform, and ensure that this Authorized User is no longer Authorized to access
                        and use GeoX Web Platform on your behalf; and
                      </Styled.Item>
                      <Styled.Item>
                        and take immediate steps to disable the relevant username and password if a username and
                        password is lost, stolen, missing or is otherwise compromised; and
                      </Styled.Item>
                    </ol>
                  </Styled.Item>
                  <Styled.Item>
                    not transfer usernames and passwords or allow them to be transferred between Authorized Users or
                    other individuals.
                  </Styled.Item>
                </ol>
              </Styled.ListItem>
              <Styled.ListItem>
                We reserve the right to change or revoke usernames and passwords at any time. If we do so, we will
                provide you with written notice.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Your responsibilities and obligations
            <Styled.OrderedList>
              <Styled.ListItem>
                You are responsible for ensuring that your use, and use by your Authorized Users, of GeoX Web Platform
                complies with all applicable laws, regulations or codes of conduct and does not infringe the
                Intellectual Property or other rights of any person.
              </Styled.ListItem>
              <Styled.ListItem>
                You must:
                <ol type="a">
                  <Styled.Item>
                    not modify, adapt, translate, reverse engineer, de-compile, disassemble or copy all or any part of
                    GeoX Web Platform or the GeoX Web Platform Website;
                  </Styled.Item>
                  <Styled.Item>
                    immediately notify us you become aware of any breach of the provisions of these GeoX Web Platform
                    Terms by you or an Authorized User;
                  </Styled.Item>
                  <Styled.Item>
                    comply with any Documentation made available by us to you from time to time;
                  </Styled.Item>
                  <Styled.Item>
                    not attempt to circumvent or break any encryption, decryption or other security device or
                    technological protection measure contained in GeoX Web Platform;
                  </Styled.Item>
                  <Styled.Item>
                    not distribute any part of GeoX Web Platform for commercial purposes or otherwise sub-license or
                    resell access to GeoX Web Platform;
                  </Styled.Item>
                  <Styled.Item>
                    not permit any person other than your Authorized Users to use GeoX Web Platform; and
                  </Styled.Item>
                  <Styled.Item>
                    not, and must ensure that your Authorized Users do not, upload any Unacceptable Content to GeoX Web
                    Platform.
                  </Styled.Item>
                </ol>
              </Styled.ListItem>
            </Styled.OrderedList>
            <Styled.ListItem>
              If we reasonably believe that any Unacceptable Content has been uploaded to GeoX Web Platform, we may
              remove this Unacceptable Content.
            </Styled.ListItem>
          </Styled.ListItem>

          <Styled.ListItem>
            Privacy
            <Styled.OrderedList>
              <Styled.ListItem>
                We handle any Personal Information collected in the course of providing you and your Authorized Users
                with GeoX Web Platform in accordance with our <Link to={ROUTE_PATHS.PRIVACY}>Privacy Policy</Link>.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Availability
            <Styled.OrderedList>
              <Styled.ListItem>
                We strive to make GeoX Web Platform and the GeoX Web Platform Website available 24/7, but from time to
                time GeoX Web Platform or the GeoX Web Platform Website, or some of their features or functions, may be
                unavailable (for example, due to scheduled or unscheduled maintenance of GeoX Web Platform, the GeoX Web
                Platform Website or GeoX Infrastructure). We will give you notice of any scheduled unavailability of
                GeoX Web Platform or the GeoX Web Platform Website, where we can reasonably do so.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Termination
            <Styled.OrderedList>
              <Styled.ListItem>
                If you are using GeoX Web Platform as part of a Trial only, we may terminate or suspend your GeoX Web
                Platform Account (at our discretion) with immediate effect and without cause by giving a termination
                notice to you.
              </Styled.ListItem>
              <Styled.ListItem>
                If you use GeoX Web Platform other than as part of a Trial (for example, as part of a Subscription
                Agreement), we may, by written notice to you, terminate your GeoX Web Platform Account with immediate
                effect if:
                <ol type="a">
                  <Styled.Item>
                    you fail to comply with any written notice issued by us requiring you to remedy a breach,
                    non-observance or non-performance of your obligations under these GeoX Web Platform Terms within
                    seven days of receiving that notice from us;
                  </Styled.Item>
                  <Styled.Item>
                    we terminate your Subscription Agreement as a result of you breaching your Subscription Agreement;
                  </Styled.Item>
                  <Styled.Item>
                    you commit a breach of these GeoX Web Platform Terms which is incapable of remedy;
                  </Styled.Item>
                  <Styled.Item>
                    you go into bankruptcy, liquidation, are wound up, become insolvent or have a receiver appointed
                    over all or any substantial part of your assets; or
                  </Styled.Item>
                  <Styled.Item>you propose or enter into any schemes of arrangements with your creditors.</Styled.Item>
                </ol>
              </Styled.ListItem>
            </Styled.OrderedList>
            <Styled.ListItem>
              Upon termination or expiry of these GeoX Web Platform Terms you must immediately cease using and ensure
              that each Authorized User ceases to use GeoX Web Platform and we may disable all of your, and Authorized
              Users’, usernames and passwords.
            </Styled.ListItem>
            <Styled.ListItem>
              The following clauses survive the termination or expiry of these GeoX Web Platform Terms: 4, 7, 10, 11,
              12, and 13 of these GeoX Web Platform Terms.
            </Styled.ListItem>
          </Styled.ListItem>

          <Styled.ListItem>
            Confidentiality
            <Styled.OrderedList>
              <Styled.ListItem>
                Both parties:
                <ol type="a">
                  <Styled.Item>
                    may use Confidential Information solely for the purposes reasonably contemplated by these GeoX Web
                    Platform Terms;
                  </Styled.Item>
                  <Styled.Item>
                    subject to 10.1(c), must keep confidential all Confidential Information; and
                  </Styled.Item>
                  <Styled.Item>
                    may disclose Confidential Information only to (i) their employees, contractors and professional
                    advisors who (A) are aware and agree that the Confidential Information must be kept confidential and
                    (B) have a need to know the Confidential Information (and only to the extent that each has a need to
                    know); (ii) as required by law or securities exchange regulation; and (iii) with the prior written
                    consent of the other party.
                  </Styled.Item>
                </ol>
              </Styled.ListItem>
            </Styled.OrderedList>
            <Styled.ListItem>
              Each party must notify the other immediately if they become aware of any breach of confidentiality in
              respect of the Confidential Information and must take all reasonable steps necessary to prevent further
              Unauthorized use or disclosure of the Confidential Information.
            </Styled.ListItem>
          </Styled.ListItem>

          <Styled.ListItem>
            Intellectual Property
            <Styled.OrderedList>
              <Styled.ListItem>
                Unless otherwise set out in these GeoX Web Platform Terms (including clause 1), you acknowledge that
                nothing in these GeoX Web Platform Terms grants you any ownership of or rights in respect of the
                Intellectual Property in GeoX Web Platform, the GeoX Web Platform Website, our Data, or any
                Documentation.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            Liability and warranties
            <Styled.OrderedList>
              <Styled.ListItem>
                we will not be liable for any Loss suffered by you which arises out of or in connection with:
                <ol type="a">
                  <Styled.Item>
                    any computer viruses or malicious code being transferred by or obtained as a result of the use of
                    GeoX Web Platform or the GeoX Web Platform Website; or
                  </Styled.Item>
                  <Styled.Item>
                    any hacking into, data security issues or other similar attacks on GeoX Web Platform or the GeoX
                    Infrastructure;
                  </Styled.Item>
                </ol>
              </Styled.ListItem>
              <Styled.ListItem>
                we do not warrant or guarantee that:
                <ol type="a">
                  <Styled.Item>
                    your access to GeoX Web Platform or the GeoX Web Platform Website will be uninterrupted, error free
                    or free from corruption, viruses, interference, hacking or other security intrusion; or
                  </Styled.Item>
                  <Styled.Item>
                    GeoX Web Platform or the GeoX Web Platform Website will be accurate, reliable or fit for any
                    particular purpose.
                  </Styled.Item>
                </ol>
              </Styled.ListItem>
              <Styled.ListItem>
                Neither party will be liable for any Consequential Loss (however caused) suffered or incurred by the
                other party in connection with these GeoX Web Platform terms.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>
            General provisions
            <Styled.OrderedList>
              <Styled.ListItem>
                These GeoX Web Platform Terms are governed by the laws in force in XXXXXX, and the parties submit to the
                non-exclusive jurisdiction of the courts of XXXXXXX.
              </Styled.ListItem>
              <Styled.ListItem>
                A party will not be liable for any failure to perform or delay in performing its obligations under these
                GeoX Web Platform terms if that failure or delay is due to a Force Majeure Event and it has provided
                notice to the other party of the Force Majeure Event.
              </Styled.ListItem>
              <Styled.ListItem>
                If any provision of these GeoX Web Platform terms is void, unenforceable or illegal and would not be so
                if a word or words were omitted, then that word or those words are to be severed and if this cannot be
                done, the entire provision is to be severed from these GeoX Web Platform terms without affecting the
                validity or enforceability of the remaining provisions of these GeoX Web Platform terms.
              </Styled.ListItem>
            </Styled.OrderedList>
          </Styled.ListItem>

          <Styled.ListItem>Definitions</Styled.ListItem>
        </Styled.OrderedList>

        <Styled.Text>In these GeoX Web Platform terms:</Styled.Text>
        <Styled.Text>
          <strong>Authorized Users</strong> has the meaning given in clause 2.1.
        </Styled.Text>
        <Styled.Text>
          <strong>Confidential Information</strong> means any information provided by a party to the other party in
          connection with GeoX Web Platform or these GeoX Web Platform Terms but does not include information which is
          in or becomes part of the public domain, other than through a breach of these GeoX Web Platform Terms or of an
          obligation of confidence, or information which a party proves was independently acquired or developed without
          breaching any of the obligations set out in these GeoX Web Platform Terms.
        </Styled.Text>
        <Styled.Text>
          <strong>Consequential Loss</strong> means:
        </Styled.Text>
        <ol type="a">
          <Styled.Item>
            any form of indirect, special or consequential loss, including loss of reputation, loss of profits, loss of
            data, loss of actual or anticipated savings, loss of bargain and loss of opportunity; and
          </Styled.Item>
          <Styled.Item>any loss beyond the normal measure of damages.</Styled.Item>
        </ol>

        <Styled.Text>
          <strong>Data</strong> means the information and data made available to you and Authorized Users through GeoX
          Web Platform.
        </Styled.Text>
        <Styled.Text>
          <strong>Documentation</strong> means any manuals, guides, reference materials or other similar documents in
          any form made available by us to you in connection with GeoX Web Platform.
        </Styled.Text>
        <Styled.Text>
          <strong>Force Majeure Event</strong> means any event arising from, or attributable to, acts, events, omissions
          or accidents which are beyond the reasonable control of a party.
        </Styled.Text>
        <Styled.Text>
          <strong>GeoX Web Platform Account</strong> means the account that you register for in order to be able to
          access and use GeoX Web Platform.
        </Styled.Text>
        <Styled.Text>
          <strong>GeoX Web Platform Portal</strong> means the data delivery platform which is made available by us at{" "}
          <a href="https://geoxanalytics.com" target="_blank" rel="noopener noreferrer">
            https://geoxanalytics.com
          </a>{" "}
          for the use by you or your Authorized Users from time to time including any Update to that platform
          implemented by us and any improvements to that platform.
        </Styled.Text>
        <Styled.Text>
          <strong>Intellectual Property</strong> includes all patents, designs, copyright, trade marks or circuit layout
          rights and any right to apply for the registration or grant of any of the above.
        </Styled.Text>
        <Styled.Text>
          <strong>Loss</strong> means any loss, liability, cost, claim, expense, damage, charge, penalty, outgoing or
          payment however arising, whether present, unascertained, immediate, future or contingent, and includes direct
          loss and Consequential Loss.
        </Styled.Text>
        <Styled.Text>
          <strong>Personal Information</strong> has the meaning given to it under the Privacy Act 1988 (Cth).
        </Styled.Text>
        <Styled.Text>
          <strong>PDH Services</strong> means services which, for the purposes of the Australian Consumer Law, are of a
          kind ordinarily acquired for personal, domestic or household use or consumption.
        </Styled.Text>
        <Styled.Text>
          <strong>GeoX Infrastructure</strong> means the computer servers or other hardware used by us in connection
          with our provision of GeoX Web Platform.
        </Styled.Text>
        <Styled.Text>
          <strong>GeoX Policies</strong> means our then current policies regarding the use of GeoX Web Platform, as we
          may notify you of from time to time.
        </Styled.Text>
        <Styled.Text>
          <strong>Subscription Agreement</strong> means an agreement between you and us to subscribe to purchase our
          Data through GeoX Web Platform.
        </Styled.Text>
        <Styled.Text>
          <strong>Third Party Application</strong> means any product, service, system, application or internet site
          integrated or interfaced with GeoX Web Platform that is owned or operated by a Third Party Provider.
        </Styled.Text>
        <Styled.Text>
          <strong>Third Party Provider</strong> means any third party that products or services that are used by you or
          an Authorized User in connection with GeoX Web Platform.
        </Styled.Text>
        <Styled.Text>
          <strong>Third Party Provider Terms</strong> has the meaning given to in pursuant to clause 4.4.
        </Styled.Text>
        <Styled.Text>
          <strong>Trial</strong> means allowing you access to our Trial Data on a short term trial basis.
        </Styled.Text>
        <Styled.Text>
          <strong>Trial Data</strong> means any Data that we allow you to access through GeoX Web Platform without
          signing up to a GeoX Web Platform subscription.
        </Styled.Text>
        <Styled.Text>
          <strong>Unacceptable Content</strong> means:
        </Styled.Text>
        <ol type="a">
          <Styled.Item>
            content which, in our reasonably held opinion, is obscene, offensive, upsetting, defamatory, illegal or
            inappropriate, infringes or appears to infringe the Intellectual Property rights of any person or
            contravenes or appears to contravene any applicable laws, regulations or codes of conduct;
          </Styled.Item>
          <Styled.Item>content which is incorrect, false or misleading; or</Styled.Item>
          <Styled.Item>
            files with any viruses, malicious code or other conditions which could damage or interfere with data,
            hardware or software.
          </Styled.Item>
        </ol>
        <Styled.Text>
          <strong>We or us</strong> means GeoX GIS Innovations Ltd , registered co: 515832186.
        </Styled.Text>
        <Styled.Text>
          <strong>You or your</strong> means the person (where an individual has agreed to these terms on their own
          behalf) or entity (where an individual has agreed to these terms on behalf of an entity) who registers with us
          for a GeoX Web Platform Account.
        </Styled.Text>
      </Styled.Wrapper>
    </>
  );
}
