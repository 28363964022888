import styled from "styled-components/macro";
import { css } from "styled-components";

import { baseButtonStyle, BORDER_RADIUS, BOX_SHADOW, COLORS, QUERIES, WEIGHT } from "configs/styles/common";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid white;
  bottom: -10px;
  filter: drop-shadow(0 0 6px hsl(0deg 0% 0% / 10%));
`;

export const ListWrapper = styled.div.attrs({
  id: "box",
})`
  z-index: 999;
  display: flex;
  cursor: initial;
  width: 395px;
  max-width: 35%;
  max-height: 900px;
  min-height: 130px;
  padding: 15px;
  gap: 15px;
  flex-direction: column;
  overflow: auto;
  text-align: center;
  position: absolute;
  top: 79px;
  right: 4vw;
  background-color: ${COLORS.LIGHT.White};
  box-shadow: ${BOX_SHADOW.REGULAR};
  border-radius: ${BORDER_RADIUS.XS};

  @media ${QUERIES.upToTablet} {
    top: 110px;
    max-width: 60%;
    right: 8px;
  }

  @media ${QUERIES.upToMobile} {
    max-width: 80%;
    top: 85px;
  }
`;

export const BellWrapper = styled.button`
  ${baseButtonStyle};
  position: relative;
  color: ${COLORS.DARK.Main};
  flex-shrink: 0;

  & svg {
    height: 30px;
  }

  ${({ isNewNotification }) =>
    isNewNotification &&
    css`
      &::after {
        position: absolute;
        content: "";
        background-color: ${COLORS.DARK.Main};
        width: 7px;
        height: 7px;
        border-radius: 50%;
        right: 3px;
        top: 1px;
      }
    `}
`;

export const Bell = styled.img`
  width: auto;
  height: ${({ indicate }) => !indicate && "19px"};

  @media ${QUERIES.upToTablet} {
    height: 30px;
  }
`;

export const Label = styled.div`
  color: ${COLORS.DARK.Main};
  padding: 8px 25px;
  text-align: justify;
  user-select: none;
  position: relative;
  transition: transform 300ms ease-out;
  cursor: pointer;
  background-color: #fff;
  box-shadow: ${BOX_SHADOW.LIGHT};
  border-radius: ${BORDER_RADIUS.XS};

  &:hover {
    transform: scale(1.01);
  }
`;

export const NotificationTitle = styled.h5`
  font-size: 14px;
  font-weight: ${WEIGHT.bold};
  color: #16151c;
  margin-bottom: 5px;
`;

export const Description = styled.div`
  font-size: 12px;
  font-size: ${({ lang }) => lang === "ja" && "10px"};
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const Time = styled.p`
  font-size: 13px;
  color: ${COLORS.GREY.Form};
  text-align: right;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  margin: 0;
`;
