import React from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "./styles";

export function ToggleSwitch(props) {
  const { optionOne, optionTwo, onOptionOne, onOptionTwo, switchMode, width, ...rest } = props;
  const { t, i18n } = useTranslation();

  function unify(option) {
    return option
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[^\w\d]/g, "-");
  }

  const dataModeOne = unify(optionOne);
  const dataModeTwo = unify(optionTwo);

  return (
    <Styled.ToggleSwitch switchMode={switchMode} id="toggle-switch" width={width} {...rest}>
      <Styled.ToggleSwitchButton
        data-mode={dataModeOne}
        onClick={onOptionOne}
        width={width}
        lang={i18n.language}
        {...rest}
      >
        {t(optionOne)}
      </Styled.ToggleSwitchButton>

      <Styled.ToggleSwitchButton
        data-mode={dataModeTwo}
        onClick={onOptionTwo}
        width={width}
        lang={i18n.language}
        {...rest}
      >
        {t(optionTwo)}
      </Styled.ToggleSwitchButton>
    </Styled.ToggleSwitch>
  );
}
