import { processStatuses } from "./getStatus";

export function getMessage(status) {
  let message;

  switch (status) {
    case processStatuses.ready:
      message = "";
      break;
    case processStatuses.failed:
      message =
        "Unfortunately, we were unable to process it. Please try again later or contact us for more information.";
      break;
    case processStatuses.imagesReady:
      message = "All images for the project are ready.";
      break;
    case processStatuses.lowCredits:
      message = "Please, renew your plan. You have not enough credits to process the project.";
      break;
    case processStatuses.noCredits:
      message = "All credits are used, please renew your plan.";
      break;
    default:
      message = "";
  }

  return message;
}
