import styled from "styled-components";

import { COLORS, QUERIES } from "configs/styles/common";

export const ErrorFallBackPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// 291px - it's the sum of header - 56px and footer - 235px

export const Wrapper = styled.div`
  flex-grow: 1;
  background-color: ${COLORS.LIGHT.White};
  width: 100%;
  height: calc(100vh - 291px);
  min-height: 540px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${QUERIES.upToTablet} {
    height: calc(100vh - 100px);
    padding-top: 75px;
  }

  @media ${QUERIES.upToMobile} {
    padding-top: 50px;
  }
`;

export const MessageContainer = styled.div`
  position: absolute;
  background-color: rgb(255 255 255 / 60%);
  max-width: 600px;
  margin: auto;
  min-height: 340px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  padding-top: 60px;
  gap: 16px;
  border-radius: 30px;
  box-shadow: 0 4px 39px rgb(0 0 0 / 14%);

  @media ${QUERIES.upToTablet} {
    width: 90%;
  }
`;

export const Error = styled.div`
  position: absolute;
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.ACCENT};
  border-radius: 50%;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
`;

export const ErrorText = styled.p`
  font-size: 64px;
  font-weight: 700;
  line-height: 75px;
  color: ${COLORS.DARK.Main};
`;

export const Text = styled.p`
  font-size: 20px;
  flex-grow: 1;
  text-align: center;
  letter-spacing: 0.4px;
  font-weight: 500;

  & span {
    font-weight: 600;
  }
`;
