export default function changeThumbColor(value, node) {
  switch (value) {
    case 0:
      node.style.setProperty("--thumb-background", "#4C8046");
      break;
    case 1:
      node.style.setProperty("--thumb-background", "#92a040");
      break;
    case 2:
      node.style.setProperty("--thumb-background", "#d3be38");
      break;
    case 3:
      node.style.setProperty("--thumb-background", "#eec038");
      break;
    case 4:
      node.style.setProperty("--thumb-background", "#e3a93c");
      break;
    case 5:
      node.style.setProperty("--thumb-background", "#d99040");
      break;
    case 6:
      node.style.setProperty("--thumb-background", "#c76f3f");
      break;
    case 7:
      node.style.setProperty("--thumb-background", "#b54c3e");
      break;
    case 8:
      node.style.setProperty("--thumb-background", "#a1455b");
      break;
    case 9:
      node.style.setProperty("--thumb-background", "#8b528c");
      break;
    default:
      node.style.setProperty("--thumb-background", "#775FBB");
  }
}
