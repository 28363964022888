import styled from "styled-components/macro";

import { CheckSVG, CrossSVG } from "assets/images/modals";
import { baseButtonStyle, COLORS, ModalWrapper } from "configs/styles/common";

export const AlertModalWrapper = styled(ModalWrapper)`
  padding-top: 50px;

  &::after {
    content: "";
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: ${COLORS.ACCENT};
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: ${(props) => (props.type === "success" ? `url(${CheckSVG})` : `url(${CrossSVG})`)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 44%;
  }
`;

export const ChatButton = styled.button`
  ${baseButtonStyle}
`;
