import React from "react";
import styled from "styled-components/macro";

const Heading = styled.h1`
  font-family: Bitter, serif;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: 0;
  line-height: 120%;
  color: #23242a;

  @media (max-width: 550px) {
    font-size: ${({ fontSize }) => `${fontSize / 1.2}px`};
  }
`;

export function Title({ children, as = "h1", fontSize = 26, fontWeight = 700, textAlign = "center" }) {
  return (
    <Heading as={as} fontSize={fontSize} fontWeight={fontWeight} textAlign={textAlign}>
      {children}
    </Heading>
  );
}
