import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";
import AvatarImg from "assets/icons/user-avatar.svg";

import { getUserPicture } from "./userAPI";

export function useAvatar(isChanged) {
  const { data: avatar = "" } = useQuery({
    queryKey: [queryKeys.avatar, isChanged],
    queryFn: getUserPicture,
  });

  const userAvatar = avatar ? `data:image/png;base64, ${avatar}` : AvatarImg;

  return { userAvatar };
}
