import React from "react";
import { useTranslation } from "react-i18next";

import { ModalButton, ModalText, ModalTitle } from "configs/styles/common";
import { openTidioChat } from "services/helpers/utils";

import * as Styled from "./styles";

export function ErrorModal({ close }) {
  const { t } = useTranslation("modals");

  return (
    <Styled.AlertModalWrapper type="error">
      <ModalTitle>
        {t("Oops! Something")} <br /> {t("Went Wrong")}
      </ModalTitle>

      <ModalText>
        {t("Contact our support on the")}{" "}
        <Styled.ChatButton
          onClick={() => {
            close();
            openTidioChat();
          }}
        >
          {t("chat")}
        </Styled.ChatButton>
      </ModalText>

      <ModalButton onClick={close}>{t("OK")}</ModalButton>
    </Styled.AlertModalWrapper>
  );
}
