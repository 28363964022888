import basicConfig from "./basicConfig";

const _ = undefined;

const orderAPI = {
  orderProject(project_id) {
    const params = {
      project_id,
    };

    return basicConfig
      .createRequest("/order/place", "POST", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  adminFilterBills(payload) {
    return basicConfig
      .createRequest("/v1/admin/bill/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminFilterCreditStatements(payload) {
    return basicConfig
      .createRequest("/v1/admin/credits-statements/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },
};

export default orderAPI;
