import React, { createContext, useContext, useMemo } from "react";

import { useStorage } from "../../../../services/hooks";

const CountryContext = createContext();

export function CountryProvider({ children }) {
  const [country, setCountry] = useStorage("country", { value: "jp", label: "JP", subdivision: "prefecture" });

  const value = useMemo(() => ({ country, setCountry }), [country, setCountry]);

  return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>;
}

export function useCountry() {
  return useContext(CountryContext);
}
