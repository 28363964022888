import React from "react";
import styled from "styled-components/macro";

import { QUERIES } from "configs/styles/common";

// space = { desktop: 40, tablet: 25, mobile : 10 } or
// space = { desktop: 40 }

export function Spacer({ space }) {
  const { desktop, tablet, mobile } = space;

  return <StyledSpacer desktop={desktop} tablet={tablet} mobile={mobile} />;
}

const StyledSpacer = styled.div`
  margin-bottom: ${({ desktop }) => `${desktop}px`};

  @media ${QUERIES.upToLaptop} {
    margin-bottom: ${({ desktop }) => `${desktop}px`};
  }

  @media ${QUERIES.upToTablet} {
    margin-bottom: ${({ tablet, desktop }) => (tablet >= 0 ? `${tablet}px` : `${desktop * 0.6}px`)};
  }

  @media ${QUERIES.upToMobile} {
    margin-bottom: ${({ mobile, desktop }) => (mobile >= 0 ? `${mobile}px` : `${desktop * 0.4}px`)};
  }
`;
