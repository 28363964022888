import enLocale from "date-fns/locale/en-US";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export function useLocale() {
  const [locale, setLocale] = useState(enLocale);
  const { i18n } = useTranslation();

  useEffect(() => {
    const importLocaleFile = async () => {
      const localeToSet = await import(
        /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
        `date-fns/locale/${i18n.language}/index.js`
      );
      setLocale(localeToSet.default);
    };

    if (locale.code !== i18n.language) {
      importLocaleFile();
    }
  }, [i18n.language, locale.code]);

  return [locale, setLocale];
}
