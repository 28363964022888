import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Tooltip } from "components/presenters/common";
import { EditButton } from "components/presenters/common/Button";
import { COLORS, QUERIES, WEIGHT } from "configs/styles/common";
import { Mixpanel } from "services/helpers/common";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 200px;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: ${WEIGHT.semiBold};
  color: ${COLORS.DARK.Secondary};
  max-height: 38px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 2px solid transparent;

  @media ${QUERIES.upToTablet} {
    font-size: 16px;
  }
`;

const Input = styled.input.attrs(() => ({
  type: "text",
  autoFocus: true,
}))`
  outline: none;
  border-width: 0 0 2px;
  border-color: ${COLORS.ACCENT};
  width: 100%;
  height: 35px;
  font-size: 18px;
  font-weight: ${WEIGHT.semiBold};
  color: ${COLORS.DARK.Secondary};
  padding: 0;
`;

const Button = styled(EditButton)`
  flex-shrink: 0;
  align-self: start;
  height: 35px;
  width: 40px;
  transition: all 0.3s ease-out;
  pointer-events: auto;
  z-index: 20;
  position: relative;
`;

function EditTitle({ name, submitName }) {
  const [isEdit, setIsEdit] = useState(false);
  const [nextName, setNextName] = useState(name);
  const { t } = useTranslation("tooltips");

  useEffect(() => {
    setNextName(name);
  }, [name]);

  function onInputBlur() {
    if (nextName) {
      Mixpanel.track("Change project name from Project list page");
      submitName(nextName);
    }
    setIsEdit(false);
  }

  return (
    <Wrapper>
      {!isEdit ? (
        <Name>{nextName}</Name>
      ) : (
        <Input
          onBlur={onInputBlur}
          value={nextName}
          onChange={(e) => setNextName(e.target.value)}
          data-testid="edit-input"
        />
      )}
      <Tooltip content={<span>{t("Edit Project Name")}</span>}>
        <Button
          data-testid="edit-title"
          variant="outline"
          name="editButton"
          onClick={() => setIsEdit((prev) => !prev)}
        />
      </Tooltip>
    </Wrapper>
  );
}

export default memo(EditTitle);
