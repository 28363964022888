export function animate(withDelay = false) {
  const dotsSVGs = document.querySelectorAll(".dots");
  dotsSVGs.forEach((svg, svgIndex) => {
    const dots = svg.querySelectorAll("circle");
    dots.forEach((dot, index) => {
      const delay = index * 6 >= 10 ? index * 6 : "0" + index * 6;
      const complicatedDelay = withDelay ? `${svgIndex}.${delay}s` : `0.${delay}s`;

      dot.style.animationDelay = complicatedDelay;
    });
  });
}
