import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { PublicRoute, BaseRoute, PrivateRoute, AdminRoute } from "services/helpers/routing";
import {
  AdminLogin,
  AdminPage,
  ManagePlans,
  ManageUsers,
  ManageCoupons,
  ManageProjects,
  ManageContactUs,
  ManageFeedback,
  LoginActivity,
  ManageOrders,
  ManageBills,
} from "pages/admin";
import {
  PlatformPage,
  CookieNotice,
  ForgotPasswordPage,
  UnsubscribePage,
  SignInPage,
  SignUpPage,
  ContactUsPage,
  EmailVerification,
  HomePage,
  ResetPasswordPage,
  AboutUs,
  Privacy,
  Terms,
  News1,
  News2,
  News3,
} from "pages/Public";
import {
  NotFoundPage,
  AddressViewPage,
  SingleSearchPage,
  ProjectsList,
  ChangePasswordPage,
  PdfExport,
  SettingsPage,
  ProjectPage,
  ProjectViewPage,
  ComparisonPage,
} from "pages/Private";

import { CountryProvider, FiltersProvider, MapProvider } from "../business";
import { ROUTE_PATHS } from "./consts";

export default function AppRouter() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <PublicRoute path="/" exact component={HomePage} />
        <PublicRoute path={ROUTE_PATHS.HOME} component={HomePage} />

        <BaseRoute path={ROUTE_PATHS.ADMIN_LOGIN} exact component={AdminLogin} />
        <BaseRoute path={ROUTE_PATHS.SIGN_IN} component={SignInPage} />
        <BaseRoute path={ROUTE_PATHS.VERIFY_EMAIL} component={EmailVerification} />
        <BaseRoute path={ROUTE_PATHS.SIGN_UP} component={SignUpPage} />
        <BaseRoute path={ROUTE_PATHS.COOKIE_NOTICE} component={CookieNotice} />
        <BaseRoute path={ROUTE_PATHS.PRIVACY} component={Privacy} />
        <BaseRoute path={ROUTE_PATHS.TERMS} component={Terms} />
        <BaseRoute path={ROUTE_PATHS.CONTACT_US} component={ContactUsPage} />
        <BaseRoute path={ROUTE_PATHS.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        <BaseRoute path={ROUTE_PATHS.RESET_PASSWORD} component={ResetPasswordPage} />
        <BaseRoute path={ROUTE_PATHS.UNSUBSCRIBE} component={UnsubscribePage} />
        <BaseRoute path={ROUTE_PATHS.ABOUT_US} component={AboutUs} />
        <BaseRoute path={ROUTE_PATHS.PLATFORM} component={PlatformPage} />
        <BaseRoute path={ROUTE_PATHS.NEWS_1} component={News1} />
        <BaseRoute path={ROUTE_PATHS.NEWS_2} component={News2} />
        <BaseRoute path={ROUTE_PATHS.NEWS_3} component={News3} />
        <BaseRoute exact path={ROUTE_PATHS.LINKEDIN} component={LinkedInCallback} />

        <AdminRoute path={ROUTE_PATHS.ADMIN} exact component={AdminPage} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_PLANS} exact component={ManagePlans} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_USERS} exact component={ManageUsers} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_COUPONS} exact component={ManageCoupons} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_PROJECTS} exact component={ManageProjects} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_CONTACT_US} exact component={ManageContactUs} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_FEEDBACK} exact component={ManageFeedback} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_LOGIN_ACTIVITY} exact component={LoginActivity} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_ORDERS} exact component={ManageOrders} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_BILLS} exact component={ManageBills} />

        <CountryProvider>
          <FiltersProvider>
            <MapProvider>
              <PrivateRoute path={ROUTE_PATHS.SINGLE_SEARCH} component={SingleSearchPage} />
              <PrivateRoute path={ROUTE_PATHS.PROJECTS} component={ProjectsList} />
              <PrivateRoute path={ROUTE_PATHS.PROJECT} component={ProjectPage} />
              <PrivateRoute path={ROUTE_PATHS.PROJECT_VIEW_PAGE} component={ProjectViewPage} />
              <PrivateRoute path={ROUTE_PATHS.ADDRESS_VIEW_PAGE} component={AddressViewPage} />
              <PrivateRoute path={ROUTE_PATHS.PDF_EXPORT} component={PdfExport} />
              <PrivateRoute path={ROUTE_PATHS.SETTINGS} component={SettingsPage} />
              <PrivateRoute path={ROUTE_PATHS.CHANGE_PASSWORD} component={ChangePasswordPage} />
              <PrivateRoute path="/comparison/:projectId" component={ComparisonPage} />
            </MapProvider>
          </FiltersProvider>
        </CountryProvider>

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}
