export function getTitleBasedOnError(error) {
  let title;

  if (error instanceof Error) {
    title = error.message === "Request failed with status code 500" ? "Server is not reachable" : error.message;
  } else {
    title = "Error connecting to server";
  }

  return title;
}
