import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: inline-block;
`;

const AvatarImage = styled.img`
  object-fit: cover;
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export function Avatar({ avatar, alt }) {
  return (
    <Wrapper>
      <AvatarImage src={avatar} alt={alt} />
    </Wrapper>
  );
}
