import basicConfig from "./basicConfig";

const planAPI = {
  adminGetPlans() {
    return basicConfig.createRequest("/v1/admin/plans", "GET").then((res) => res.data);
  },

  adminUpdatePlan(id, payload) {
    return basicConfig
      .createRequest(`/v1/admin/plans/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminCreatePlan(payload) {
    return basicConfig
      .createRequest("/v1/admin/plans", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminDeletePlan(id) {
    return basicConfig.createRequest(`/v1/admin/plans/${id}`, "DELETE").then((res) => res.data);
  },

  adminFilterCoupons(payload) {
    return basicConfig
      .createRequest("/v1/admin/coupons/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminUpdateCoupon(id, payload) {
    return basicConfig
      .createRequest(`/v1/admin/coupons/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminCreateCoupon(payload) {
    return basicConfig
      .createRequest("/v1/admin/coupons", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },
};

export default planAPI;
