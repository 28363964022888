import { S3 } from "services/constants/apiConstants";

export const ArrowDownSVG = S3 + "assets/shared/icons/arrow_down_icon.svg";

export const BellNotifiedSVG = S3 + "assets/shared/icons/bell_notificate.svg";
export const BigSearchSVG = S3 + "assets/shared/icons/search_icon_yellow.svg";
export const BinPNG = S3 + "assets/delete.png";

export const CameraSVG = S3 + "assets/camera.svg";
export const CheckMarkSVG = S3 + "assets/check1.svg";
export const CheckSVG = S3 + "assets/check.svg";
export const CloseSVG = S3 + "assets/close.svg";
export const CloseSVGYellow = S3 + "assets/shared/icons/close_icon_yellow.svg";
export const CollapseBoldSVG = S3 + "assets/shared/icons/collapse.svg";
export const CollapseThinSVG = S3 + "assets/shared/icons/collapse_arrow.svg";
export const CollapseYellowPNG = S3 + "assets/collapse.png";
export const CrossSVG = S3 + "assets/cross.svg";

export const DecreaseSVG = S3 + "assets/shared/icons/decrease_icon.svg";
export const DeleteSVG = S3 + "assets/shared/icons/x.svg";
export const DragPNG = S3 + "assets/drag.png";

export const EditSVG = S3 + "assets/shared/icons/pen.svg";
export const ExcelSVG = S3 + "assets/shared/icons/excel_icon.svg";
export const ExportSVG = S3 + "assets/shared/icons/export.svg";

export const FavoriteStarIcon = S3 + "assets/star_yellow.svg";
export const FeedbackSVG = S3 + "assets/shared/icons/feedback.svg";
export const FilledBellSVG = S3 + "assets/fill-bell.svg";

export const HomeSvg = S3 + "assets/shared/icons/building_count.svg";
export const IncreaseSVG = S3 + "assets/shared/icons/increase_icon.svg";
export const LangIcon = S3 + "assets/shared/icons/language-icon.svg";
export const LayersSVG = S3 + "assets/shared/icons/layers.svg";
export const LeftArrowSVG = S3 + "assets/leftArrow.svg";
export const LinkedinPNG = S3 + "assets/linkedin.png";
export const LinkedinSVG = S3 + "assets/shared/icons/linkedin.svg";

export const MapSVG = S3 + "assets/shared/icons/map.svg";
export const MapViewSVG = S3 + "assets/shared/icons/mapView.svg";
export const MarkerSVG = S3 + "assets/shared/icons/pinpoint.svg";

export const MenuSVG = S3 + "assets/menu.svg";
export const NextSVG = S3 + "assets/shared/icons/next_icon_gray.svg";
export const NotFoundImage = S3 + "assets/shared/nonFoundImage.png";

export const PaginationArrowSVG = S3 + "assets/shared/icons/pagination-arrow_icon.svg";
export const PlayIcon = S3 + "assets/play_video.png";

export const RightArrowSVG = S3 + "assets/rightArrow.svg";
export const SaveSVG = S3 + "assets/save.svg";
export const SearchSVG = S3 + "assets/shared/icons/search_icon.svg";
export const StarBoldSVG = S3 + "assets/star.svg";
export const StarThinSVG = S3 + "assets/shared/icons/star_icon.svg";
export const SupportArrowSVG = S3 + "assets/support_arrow.svg";

export const TableViewSVG = S3 + "assets/shared/icons/tableView.svg";
export const UnFilledBellSVG = S3 + "assets/empty-bell.svg";
export const ViewSVG = S3 + "assets/shared/icons/view.svg";
export const YoutubePNG = S3 + "assets/youtube.png";

export const DisabledLayersSVG = S3 + "assets/shared/icons/disabledLayers.svg";
export const MapLayerSVG = S3 + "assets/shared/icons/map-layer.svg";
export const MarkerOrangeSVG = S3 + "assets/shared/icons/orange-marker.svg";
export const MarkerGreySVG = S3 + "assets/shared/icons/grey-marker.svg";

export const ErrorSignImg = S3 + "assets/shared/icons/error.png";
