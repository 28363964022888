import React from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
  padding: 12px 20px;
  width: 100%;
  min-height: ${(props) => (props.isSmall ? "94px" : "343px")};
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(110 120 152 / 15%);
  box-shadow: none;
  outline: none;
  font-weight: 500;

  &::placeholder {
    font-size: inherit;
    font-family: inherit;
    color: #ccc;
  }

  &:focus {
    outline: none;
  }
`;

const TextArea = ({ placeholder, value, onChange, small = false }) => {
  return <StyledTextArea placeholder={placeholder} value={value} onChange={(e) => onChange(e)} isSmall={small} />;
};

export default TextArea;
