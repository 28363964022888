import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as CrossSVG } from "assets/images/modals/cross_icon.svg";
import { Button } from "components/presenters/common";
import { Header, Footer } from "components/presenters/layouts";
import { ROUTE_PATHS } from "app/providers/router";

import * as Styled from "./NotFoundPage.style";

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <>
      <Header />

      <Styled.Wrapper data-testid="not-found-page">
        <Styled.MessageContainer>
          <Styled.Error>
            <CrossSVG />
          </Styled.Error>

          <Styled.ErrorText>404</Styled.ErrorText>

          <Styled.Text>
            {t("The page you are looking for")} <span>{t("does not exist.")}</span>
          </Styled.Text>

          <Link to={ROUTE_PATHS.HOME} style={{ width: "100%" }}>
            <Button style={{ maxWidth: "260px", height: "46px", color: "#23242a", margin: "auto" }}>{t("Back")}</Button>
          </Link>
        </Styled.MessageContainer>
      </Styled.Wrapper>

      <Footer />
    </>
  );
}
