import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Range } from "components/inputs";

import TextArea from "./TextArea";

const RangeItemContainer = styled.div`
  margin-bottom: 10px;
  width: 96%;

  @media screen and (min-width: 1780px) {
    width: 95%;
  }
`;

const Label = styled.p`
  margin-bottom: 20px;
`;

const RangeScale = styled.div`
  width: 102%;
  display: flex;
  justify-content: space-between;
`;

const RangeScaleItem = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

const RangeItem = ({ questText, name, inputsState, setState }) => {
  const { t } = useTranslation("addressView");

  const onTextAreaChange = (e) => {
    const message = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        message,
      },
    }));
  };

  return (
    <RangeItemContainer>
      <Label>{questText}</Label>
      <Range name={name} rangeState={inputsState} setRange={setState} />
      <RangeScale>
        {Array.from(Array(11)).map((item, idx) => (
          <RangeScaleItem key={idx}>{idx}</RangeScaleItem>
        ))}
      </RangeScale>
      <TextArea
        placeholder={t("Tell us more")}
        name={name}
        value={inputsState[name]?.message}
        onChange={onTextAreaChange}
        small
      />
    </RangeItemContainer>
  );
};

export default RangeItem;
