import basicConfig from "./basicConfig";

const commonAPI = {
  contactUs(data) {
    return basicConfig
      .createRequest("/contact-us", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },
};

export default commonAPI;
