export const CHART_COLORS = [
  "#4C8046",
  "#f3ce33",
  "#da9340",
  "#ae3e3e",
  "#775FBB",
  "#BE81C6",
  "#76C3C0",
  "#E2AE86",
  "#A75F6B",
  "#72B5F3",
  "#9D9EC4",
  "#E27144",
  "#DF4A89",
  "#2D8265",
  "#01A3C8",
  "#DD5C5D",
];
