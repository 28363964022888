import { useCallback, useState } from "react";

export function useBooleanState(initialValue = false) {
  if (typeof initialValue !== "boolean") {
    console.warn("Invalid type for useBooleanState!");
  }

  const [value, setValue] = useState(initialValue);

  const toggleValue = useCallback(() => {
    setValue((currentValue) => !currentValue);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  return [value, setFalse, setTrue, toggleValue];
}
