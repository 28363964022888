import React, { useEffect, useRef } from "react";
import InputRange from "react-input-range";

import changeThumbColor from "./helpers/changeThumbColor";
import "react-input-range/lib/css/index.css";
import * as Styled from "./style";

export function Range({ name, rangeState, setRange }) {
  const rangeRef = useRef();

  useEffect(() => {
    const thumb = rangeRef.current.node.children[1].children[1].children[1];
    changeThumbColor(Number(thumb?.ariaValueNow), thumb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeState]);

  function onInputChange(value) {
    setRange((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
      },
    }));
  }

  return (
    <Styled.RangeWrapper>
      <InputRange
        allowSameValues
        formatLabel={() => null}
        maxValue={10}
        minValue={0}
        step={1}
        onChange={(value) => onInputChange(value)}
        value={rangeState[name]?.value}
        ref={rangeRef}
      />
    </Styled.RangeWrapper>
  );
}
