import styled, { createGlobalStyle, css } from "styled-components";

import { COLORS, ModalButton, QUERIES } from "configs/styles/common";

const animation = css`
  animation-name: appear;
  animation-duration: 1.5s;

  @keyframes appear {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
    body {
        height: 100%;
        overflow-y: hidden;
      
      @media ${QUERIES.upToTablet} {
        overflow-y: auto;
      }
    }
`;

export const Overlay = styled.div`
  isolation: isolate;
  inset: 0;
  position: fixed;
  background-color: rgb(0 0 0 / 80%);
  display: grid;
  place-items: center;

  ${animation}
`;

export const ElementGlow = styled.div`
  position: fixed;
  place-content: center;
  place-content: center center;
  justify-content: ${({ notCenter }) => notCenter && "stretch"};
  top: calc(${(props) => props.top}px - 11px);
  left: calc(${(props) => props.left}px - 11px);
  max-width: ${(props) => props.width}px;
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${COLORS.LIGHT.White};
  box-shadow: 0 0 35px 1px ${COLORS.ACCENT};
  box-sizing: content-box;
  padding: 11px;

  ${animation}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 380px;
  margin-bottom: 14px;
  justify-content: center;
  padding: 0 10px;

  ${(props) =>
    props.moveGuide === "top" &&
    css`
      margin-bottom: 270px;
    `}

  ${(props) =>
    props.moveGuide === "bottom" &&
    css`
      margin-top: 150px;

      @media ${QUERIES.upToMobile} {
        margin-top: 220px;
      }
    `}

  ${(props) =>
    props.moveGuide === "right" &&
    css`
      margin-left: 200px;
    `}

  ${(props) =>
    props.moveGuide === "left" &&
    css`
      margin-right: 200px;
    `}

  ${(props) =>
    props.moveGuide === "right-top" &&
    css`
      margin-left: 200px;
      margin-bottom: 270px;
    `}
`;

export const Button = styled(ModalButton)`
  font-size: ${({ isJapanese }) => isJapanese && "16px"};

  @media ${QUERIES.upToMobile} {
    font-size: ${({ isJapanese }) => isJapanese && "14px"};
  }

  &:hover {
    background-color: #ffc500;
  }
`;

export const Title = styled.h3`
  color: ${COLORS.LIGHT.White};
  user-select: none;
  text-align: center;
  line-height: 35px;
  font-size: 24px;
  font-size: ${({ isJapanese }) => isJapanese && "21px"};

  @media ${QUERIES.upToTablet} {
    font-size: 22px;
    font-size: ${({ isJapanese }) => isJapanese && "20px"};
  }
`;

export const Text = styled(Title)`
  line-height: 28px;
  font-size: 20px;
  font-size: ${({ isJapanese }) => isJapanese && "17px"};
  margin-bottom: 21px;

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
    font-size: ${({ isJapanese }) => isJapanese && "15px"};
  }
`;
