import authAPI from "./authAPI";
import commonAPI from "./commonAPI";
import orderAPI from "./orderAPI";
import planAPI from "./planAPI";
import projectAPI from "./projectAPI";
import userAPI from "./userAPI";
import filterAPI from "./filterAPI";
import singleSearchAPI from "./singleSearchAPI";

const API = {
  ...authAPI,
  ...orderAPI,
  ...planAPI,
  ...projectAPI,
  ...userAPI,
  ...commonAPI,
  ...filterAPI,
  ...singleSearchAPI,
};

export default API;
