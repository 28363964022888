import Cookies from "js-cookie";
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

function AdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get("role") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default withRouter(AdminRoute);
