import { useMediaQuery } from "react-responsive";

import { QUERIES } from "configs/styles/common";

export function useIsDeviceType() {
  const isMobile = useMediaQuery({ query: QUERIES.upToMobile });
  const isTablet = useMediaQuery({ query: QUERIES.upToTablet });
  const isLaptop = useMediaQuery({ query: QUERIES.upToLaptop });

  return { isMobile, isTablet, isLaptop };
}
