import React, { useEffect } from "react";
import ReactGA from "react-ga";
import EventSource, { sources } from "eventsourcemock";
import { useMediaQuery } from "react-responsive";

import { SEO } from "configs/seo";
import { ScrollToTop } from "services/helpers/common";

import { CookieBanner, Loader } from "../components/presenters/common";
import AppRouter, { RouterProvider } from "./providers/router";
import { QUERIES } from "../configs/styles/common";

if (window["Cypress"]) {
  Object.defineProperty(window, "EventSource", { value: EventSource });
  Object.defineProperty(window, "mockEventSources", { value: sources });
}

function App() {
  const isTabletOrMobile = useMediaQuery({ query: QUERIES.upToTablet });

  useEffect(() => {
    if (process.env.REACT_APP_GA_ENABLED === "true") {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  useEffect(() => {
    function onTidioChatApiReady() {
      window.tidioChatApi.hide();
      window.tidioChatApi.on("close", function () {
        window.tidioChatApi.hide();
      });
    }

    if (isTabletOrMobile) {
      if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady);
      } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady);
      }
    }
  }, [isTabletOrMobile]);

  return (
    <div className="App">
      <SEO title="GeoX Property Intelligence" titleTemplate="" />
      <CookieBanner />
      <ScrollToTop>
        <RouterProvider>
          <AppRouter />
        </RouterProvider>
      </ScrollToTop>

      <Loader />
    </div>
  );
}

export default App;
