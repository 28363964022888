import React, { forwardRef } from "react";

import { Tooltip } from "../../presenters/common";
import * as Styled from "./style";

export const ErrorInput = forwardRef(function ErrorInput(
  {
    error,
    lang,
    isChanged,
    isUsed,
    onChange,
    value,
    type = "text",
    hasError = false,
    height = "35px",
    testId = "",
    errorTestId = "",
    ...props
  },
  ref
) {
  const isError = error?.props?.children && isChanged && isUsed;

  return (
    <Styled.InputField>
      <Styled.StyledInput
        data-testid={testId}
        ref={ref}
        $isError={isError || hasError}
        type={type}
        error={error}
        height={height}
        lang={lang}
        onChange={onChange}
        value={value}
        {...props}
      />
      {((isChanged && isUsed && isError) || hasError) && (
        <Tooltip content={<span data-testid={errorTestId}>{error}</span>}>
          <Styled.ErrorSign height={height} data-testid={`error-sign-${errorTestId}`} />
        </Tooltip>
      )}
    </Styled.InputField>
  );
});

//you don't need to pass hasError and error if you use ValidationInput
