import styled from "styled-components/macro";
import { css } from "styled-components";
import PhoneInput from "react-phone-input-2";

import { ErrorSignImg } from "assets/images/shared";
import { BORDER_RADIUS, BOX_SHADOW, COLORS, QUERIES, WEIGHT } from "configs/styles/common";

import { text, textSM } from "../../presenters/common/Typography";

export const InputField = styled.div`
  position: relative;
  width: 100%;
`;

export const inputStyle = ({ $isError, height = "35px" }) => css`
  ${text};
  position: relative;
  width: 100%;
  height: ${height};
  border: none;
  background-color: ${$isError ? COLORS.INPUT.ErrorBg : COLORS.LIGHT.White};
  font-weight: ${WEIGHT.regular};
  font-size: ${({ lang }) => lang === "ja" && "var(--14px)"};
  appearance: none;
  border-radius: ${BORDER_RADIUS.XS};
  padding: 12px 24px;
  box-shadow: ${BOX_SHADOW.LIGHT};

  @media ${QUERIES.upToMobile} {
    ${textSM};
    height: 50px;
    font-size: ${({ lang }) => lang === "ja" && "var(--12px)"};
  }

  &::placeholder {
    color: ${$isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder};
    border-color: ${$isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder};
    text-transform: capitalize;
  }

  &:focus {
    outline: 1.5px solid ${COLORS.DARK.Main};
  }
`;

export const StyledInput = styled.input`
  ${inputStyle};
`;

export const ErrorSign = styled.div`
  width: 43px;
  height: 40px;
  background-image: url(${ErrorSignImg});
  background-size: ${({ height }) => (height === "35px" ? "75%" : "100%")};
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: ${({ height }) => (height === "35px" ? 0 : "15%")};
  right: ${({ height }) => (height === "35px" ? "7px" : "12px")};
  cursor: pointer;

  @media ${QUERIES.upToMobile} {
    top: ${({ height }) => (height === "35px" ? 0 : "7px")};
  }
`;

export const CustomPhoneInput = styled(PhoneInput)`
  width: 100%;
  height: ${({ height }) => height};

  & .form-control {
    ${inputStyle};
    height: 100%;
  }

  @media ${QUERIES.upToMobile} {
    height: 50px;
  }
`;
