export const initialFiltersState = {
  isAllFiltersClear: false,
  isSavedFilterApplied: false,
  isFullDataTableFiltering: false,
  commonValues: {
    addressKey: "",
    country: [],
    cities: [],
    states: [],
    prefectures: [],
    zips: [],
    favoriteFilter: "all", //all, favorite
  },
  advanced_filters: {
    roof: {},
    building: {},
    parcel: {},
  },
  editFilter: {
    isEdit: false,
    filterName: null,
    filterId: null,
    isCommon: false,
  },
};
