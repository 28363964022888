import { createRef, useRef, useState } from "react";

export function useHelper(names, localStorageId) {
  const [helpersState, setHelpersState] = useState(createState());
  const refs = useRef(createRefs());

  function showHelper(helperName) {
    if (!localStorage.getItem(localStorageId)) localStorage.setItem(localStorageId, "disable");

    const tempState = createTemplate(helpersState);

    tempState[helperName] = true;

    setHelpersState(tempState);
  }

  function closeHelpers() {
    if (!localStorage.getItem(localStorageId)) localStorage.setItem(localStorageId, "disable");
    setHelpersState(createTemplate(helpersState));
  }

  function createState() {
    const tempState = {};

    names.forEach((el, i) => {
      if (i === 0) {
        tempState[el] = !localStorage.getItem(localStorageId);
      } else {
        tempState[el] = false;
      }
    });

    return tempState;
  }

  function createTemplate(object) {
    const tempState = {};

    for (const key in object) {
      tempState[key] = false;
    }

    return tempState;
  }

  function refCallback(elem, name) {
    return (refs.current[name] = elem);
  }

  function createRefs() {
    const object = {};

    names.forEach((el) => (object[el] = createRef()));

    return object;
  }

  return [helpersState, showHelper, closeHelpers, refs, refCallback];
}
