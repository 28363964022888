import React from "react";

import { ModalButton, ModalText, ModalTitle } from "configs/styles/common";

import * as Styled from "./styles";

export function PopupModal({ close, title, description, type = "error" }) {
  return (
    <Styled.AlertModalWrapper type={type}>
      <ModalTitle>{title}</ModalTitle>

      <ModalText>{description}</ModalText>

      <ModalButton onClick={close} data-testid="close-popup-modal">
        OK
      </ModalButton>
    </Styled.AlertModalWrapper>
  );
}
