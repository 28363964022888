import React from "react";
import { useHistory } from "react-router-dom";

import { Header } from "components/presenters/layouts";
import { SEO } from "configs/seo";

import * as Styled from "./Policy.style";

export default function Privacy() {
  const history = useHistory();

  function onBackClick() {
    history.goBack();
  }

  return (
    <>
      <SEO title="Privacy" />
      <Header />
      <Styled.Wrapper data-testid="privacy-page">
        <Styled.Button onClick={onBackClick}>← Back</Styled.Button>
        <Styled.Title>GeoX Privacy Policy</Styled.Title>
        <Styled.Text>Effective date: June, 2022 </Styled.Text>
        <Styled.Text>
          GeoX GIS Innovations Ltd. (the <strong>&#34;Company&#34;</strong>) provides this Privacy Policy to inform you
          of our policies and procedures regarding the collection, use and disclosure of personal information we receive
          from users of{" "}
          <a href="https://geoxanalytics.com" target="_blank" rel="noopener noreferrer">
            https://geoxanalytics.com
          </a>{" "}
          (this <strong>“Site”</strong>). Your privacy is always a top priority for us. This Privacy Policy applies only
          to information that you provide to us through this Site. This Privacy Policy may be updated from time to time.
          We will notify you of any material changes by posting the new Privacy Policy on the Site. You are advised to
          consult this policy regularly for any changes. By using the Site and Services (as defined below) you are
          consenting to the collection, transfer, processing, storage, disclosure and other uses described in this
          Privacy Policy.
        </Styled.Text>
        <Styled.Text>
          As used in this policy, the terms “using” and “processing” information include using cookies on a computer,
          subjecting the information to statistical or other analysis and using or handling information in any way,
          including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining,
          disclosing and transferring information within our organization or among our affiliates.
        </Styled.Text>

        <Styled.SubTitle>Information Collection and Use</Styled.SubTitle>
        <Styled.Text>
          Our primary goals in collecting information are to provide the Site, the content available on the Site (the
          <strong>“Content”</strong>) and any services we provide to you (the <strong>“Services”</strong>), administer
          your account (if applicable), and improve our Site, Services and Content.
        </Styled.Text>

        <Styled.Emphasis>Personally Identifiable and other Information.</Styled.Emphasis>
        <Styled.List>
          <Styled.ListItem>
            When you register with us through the Site, if applicable, we will ask you for personally identifiable
            information. This refers to information about you that can be used to contact or identify you (
            <strong>“Personal Information”</strong>). Personal Information includes, but is not limited to, your name,
            phone number, credit card or other billing information, email address and home and business postal
            addresses. If you access and use the Site, Services and/or Content through a mobile device, Company may
            collect information regarding your GPS location and associated services from your mobile device.
          </Styled.ListItem>
          <Styled.ListItem>
            We also collect other information that you provide as part of registration and the administration and
            personalization of your account (e.g., without limitation, zip code (on its own) and individual preferences)
            (<strong>“Non-Identifying Information”</strong>).
          </Styled.ListItem>
          <Styled.ListItem>
            We use your Personal Information (in some cases, in conjunction with your Non-Identifying Information)
            mainly to provide, improve and personalize the Content and Services, for analytics purposes, and to
            administer your account and use of the Site.
          </Styled.ListItem>
          <Styled.ListItem>
            Certain Non-Identifying Information would be considered a part of your Personal Information if it were
            combined with other identifiers (for example, combining your zip code with your street address) in a way
            that enables you to be identified. But the same pieces of information are considered Non-Identifying
            Information when they are taken alone or combined only with other non-identifying information (for example,
            your viewing preferences). We may combine your Personal Information with Non-Identifying Information and
            aggregate it with information collected from other Company Users (defined below) to attempt to provide you
            with a better experience, to improve the quality and value of the Content and Services and to analyze and
            understand how our Site, Services and Content are used. We may also use the combined information without
            aggregating it to serve you specifically, for instance to propose a service to you according to your
            preferences or restrictions.
          </Styled.ListItem>
          <Styled.ListItem>
            We may also use your Personal Information to contact you with Company newsletters, marketing or promotional
            materials and other information that may be of interest to you. If you decide at any time that you no longer
            wish to receive such communications from us, please follow the unsubscribe instructions provided in any of
            the communications, or notify us. (See <strong>“Changing or Deleting Information”</strong>, below.)
          </Styled.ListItem>
          <Styled.ListItem>
            Except as disclosed in this Privacy Policy, the Company does not rent, sell, trade or disclose your Personal
            Information to third parties without your consent.
          </Styled.ListItem>
        </Styled.List>

        <Styled.Emphasis>Log Data.</Styled.Emphasis>
        <Styled.Text>
          When you visit the Site (a <strong>“Company User”</strong>), our servers automatically record information that
          your browser sends whenever you visit a website (<strong>“Log Data”</strong>). This Log Data may include
          information such as your computer’s Internet Protocol (<strong>“IP”</strong>) address, browser type or the
          webpage you were visiting before you came to our Site, pages of our Site that you visit, the time spent on
          those pages, activities on our Site, access times and dates, metadata, identification numbers of the devices
          you use to connect to the Site, and other statistics. We use this information to monitor and analyze use of
          the Site, Services and Content and for the Site’s technical administration, to improve the Site, to increase
          our Site’s functionality and user-friendliness, and to better tailor it to our users’ needs. We do not treat
          Log Data as Personal Information or use it in association with other Personal Information, though we may
          aggregate, analyze and evaluate such information for the same purposes as stated above regarding other
          Non-Identifying Information. We may keep Log Data after you are no longer a Company User.
        </Styled.Text>

        <Styled.SubTitle>Cookies</Styled.SubTitle>
        <Styled.Text>
          Like many websites, we use “cookies” to collect information. A cookie is a small data file that we transfer to
          your computer’s hard disk for record-keeping purposes. We use cookies for two purposes. First, we utilize
          cookies to save the identity of the logged-in user and other information for future logins to the Site.
          Second, we utilize cookies to save Non-Identifying Information to enable certain features of the Site, to
          better understand how you interact with the Site and use the Content and Services and to monitor aggregate
          usage by Company Users. You can instruct your browser, by changing its options, to stop accepting cookies or
          to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however,
          you may not be able to use all portions of the Site or all functionality of the Services.
        </Styled.Text>

        <Styled.SubTitle>Phishing</Styled.SubTitle>
        <Styled.Text>
          Identity theft and the practice currently known as “phishing” are of great concern to Company. Safeguarding
          information to help protect you from identity theft is a top priority. We do not and will not, at any time,
          request your credit card information, your account ID, login password, or national identification numbers in a
          non-secure or unsolicited e-mail or telephone communication. For more information about phishing, visit the
          Federal Trade Commission’s website.
        </Styled.Text>

        <Styled.SubTitle>Information Sharing and Disclosure</Styled.SubTitle>
        <Styled.Emphasis>Aggregate Information and Non-Identifying Information.</Styled.Emphasis>
        <Styled.Text>
          We may keep, use and share, publicly and with our partners, aggregated non-personally identifiable information
          derived from information provided by you and your authorized users and the Services, including but not limited
          to for the purpose of improving the Services and Content. Any aggregated information shared in these contexts
          will not contain your Personal Information.
        </Styled.Text>
        <Styled.Emphasis>Service Providers.</Styled.Emphasis>
        <Styled.Text>
          We may employ third party companies and individuals to facilitate our Site, to provide the Content and
          Services on our behalf, to perform Site-related services (e.g., without limitation, maintenance services,
          database management, web analytics and improvement of the Site’s features) or to assist us in analyzing how
          our Site, Services and Content are used. These third parties have access to your Personal Information only to
          perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
        </Styled.Text>
        <Styled.Emphasis>Compliance with Laws and Law Enforcement.</Styled.Emphasis>
        <Styled.Text>
          Company cooperates with government and law enforcement officials and private parties to enforce and comply
          with the law. We will disclose any information about you to government or law enforcement officials or private
          parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process
          (including but not limited to subpoenas), to protect the safety of the public or any person, or to prevent or
          stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable
          activity.
        </Styled.Text>
        <Styled.Emphasis>Business Transfers.</Styled.Emphasis>
        <Styled.Text>
          Company may sell, transfer or otherwise share some or all of its assets, including your Personal Information,
          in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy.
          Company will notify you before transferring your information to a new entity as part of a merger, acquisition,
          reorganization or sale of some or all of its assets if the new entity&#39;s processing of your information
          will materially differ from that set forth in this Privacy Policy.
        </Styled.Text>

        <Styled.SubTitle>Third Party Data</Styled.SubTitle>
        <Styled.Text>
          You shall ensure that if you provide any data of third parties, any such third party has been informed of, and
          have given its consent to, the use, processing, and transfer of data in connection with the Site.
        </Styled.Text>

        <Styled.SubTitle>Changing or Deleting Your Information</Styled.SubTitle>
        <Styled.Text>
          All Company Users may review, update, correct or delete the Personal Information in their account by
          contacting us. If you completely delete all such information, then your account may become deactivated. If you
          would like us to delete your record in our system or stop sending you any information please contact us at
          info@geox-group.com with a request that we delete your Personal Information from our database. We may retain
          an archived copy of your records as required by law or for legitimate business purposes.
        </Styled.Text>

        <Styled.SubTitle>Security</Styled.SubTitle>
        <Styled.Text>
          Company is very concerned with safeguarding your information. We employ administrative, physical and
          electronic measures designed to protect your information from unauthorized access.
        </Styled.Text>
        <Styled.Text>
          We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of
          your unencrypted electronically stored “personal data” (as defined under applicable law) to you via email or
          conspicuous posting on this Site in the most expedient time possible and without unreasonable delay, insofar
          as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the
          scope of the breach and restore the reasonable integrity of the data system.
        </Styled.Text>

        <Styled.SubTitle>International Transfer</Styled.SubTitle>
        <Styled.Text>
          Your information may be transferred to — and maintained on — computers located outside of your state,
          province, country or other governmental jurisdiction where the privacy laws may not be as protective as those
          in your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information
          represents your agreement to that transfer.
        </Styled.Text>

        <Styled.SubTitle>Links to Other Sites</Styled.SubTitle>
        <Styled.Text>
          Our Site may contain links to other websites. The fact that we link to a website is not an endorsement,
          authorization or representation of our affiliation with that third party, nor is it an endorsement of their
          privacy or information security policies or practices. We do not exercise control over third party websites.
          These other websites may place their own cookies or other files on your computer, collect data or solicit
          personal information from you. Other sites follow different rules regarding the use or disclosure of the
          personal information you submit to them. We encourage you to read the privacy policies or statements of the
          other websites you visit.
        </Styled.Text>

        <Styled.SubTitle>Contacting Us</Styled.SubTitle>
        <Styled.Text>
          If you have any questions about this Privacy Policy, please contact us at
          <a href="mailto:info@geox-group.com"> info@geox-group.com</a>.
        </Styled.Text>
      </Styled.Wrapper>
    </>
  );
}
