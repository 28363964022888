import { processedTitle } from "./processedTitle";

export const processStatuses = {
  ready: "is ready.",
  failed: "failed.",
  imagesReady: "images are ready.",
  lowCredits: "has low credits.",
  noCredits: "has no credits.",
};

export function getStatus(title) {
  let status;
  switch (title) {
    case processedTitle.ready:
      status = processStatuses.ready;
      break;
    case processedTitle.failed:
      status = processStatuses.failed;
      break;
    case processedTitle.imagesReady:
      status = processStatuses.imagesReady;
      break;
    case processedTitle.lowCredits:
      status = processStatuses.lowCredits;
      break;
    case processedTitle.noCredits:
      status = processStatuses.noCredits;
      break;
    default:
      status = "";
  }

  return status;
}
