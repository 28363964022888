export function getAttributeValue(attributeConfig, rawAttributeValue) {
  const hasNumericValue = attributeConfig.type === "number" && typeof rawAttributeValue === "string";
  const isNumericFloatValue = typeof rawAttributeValue === "number" && !Number.isInteger(rawAttributeValue);
  const isZeroValue = rawAttributeValue === "0" || rawAttributeValue === 0;
  const isNoDataValue = rawAttributeValue === "No Data";
  const isNullValue = rawAttributeValue === null || rawAttributeValue === undefined;

  let value;
  let splitValue;

  switch (true) {
    case isNoDataValue || isNullValue:
      value = "No Data";
      break;
    case isZeroValue:
      value = "0";
      break;
    case isNumericFloatValue:
      value = rawAttributeValue.toFixed(2);
      break;
    case hasNumericValue:
      splitValue = rawAttributeValue.split(" ");
      value = `${parseFloat(splitValue[0]).toFixed(2)} ${splitValue[1] !== undefined ? splitValue[1] : ""}`;
      break;
    default:
      value = rawAttributeValue;
  }

  return value;
}
