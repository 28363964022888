import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { HouseImage } from "assets/images/modals";
import { BORDER_RADIUS, BOX_SHADOW, COLORS, ModalText, ModalTitle } from "configs/styles/common";

export function UpgradeModal() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <RightSide>
        <Box>
          <Title>{t("Upgrade")}</Title>
          <ModalText>{t("Our team will contact you soon")}</ModalText>
        </Box>
      </RightSide>
      <LeftSide>
        <Image src={HouseImage} alt="house" />
      </LeftSide>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: ${BORDER_RADIUS.MODAL};
  position: relative;
`;

const RightSide = styled.div`
  background-color: ${COLORS.ACCENT};
  border-radius: ${BORDER_RADIUS.MODAL};
  flex: 1 1 50%;
  display: grid;
  place-content: center;
  padding-right: 145px;
  padding-left: 50px;
`;

const Box = styled.div`
  margin-top: -75px;
`;

const LeftSide = styled.div`
  width: 100%;
  flex: 1 1 50%;
  border-radius: ${BORDER_RADIUS.MODAL};
  margin-left: -40px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(ModalTitle)`
  margin-bottom: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  display: block;
  height: 100%;
  border-radius: ${BORDER_RADIUS.MODAL};
  object-fit: cover;
  box-shadow: ${BOX_SHADOW.REGULAR};
`;
