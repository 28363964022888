import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Title } from "components/presenters/common";
import { AlertModalWrapper } from "components/_modals/AlertModals/styles";
import { COLORS, WEIGHT } from "configs/styles/common";

const Text = styled.p`
  font-size: 14px;
  font-weight: ${WEIGHT.regular};
  line-height: 1.5;
  color: ${COLORS.DARK.Main};
  text-align: center;
  margin-bottom: 30px !important;
`;

export default function SuccessMessage() {
  const { t } = useTranslation("addressView");

  return (
    <AlertModalWrapper type="success">
      <Title as="h3">
        {t("Thank You")} <br />
        {t("for your feedback!")}
      </Title>
      <Text>
        {t("Your submission has been received,")} <br />
        {t("Our team will be in touch shortly.")}
      </Text>
    </AlertModalWrapper>
  );
}
