export const ATTRIBUTES_DESCRIPTION = [
  {
    attribute: "footprint_area",
    "en-US": "The footprint area of the building.",
    ja: "真上から見た二次元での建物床面積。",
  },
  {
    attribute: "footprint_area_sum",
    "en-US": "Amount of footprints in a given parcel.",
    ja: "真上から見た二次元での、敷地内の建物床面積の合計。",
  },
  {
    attribute: "footprint_over_area",
    "en-US": "Percentage of the parcel covered by footprints.",
    ja: "敷地内に占める、真上から見た二次元での建物床面積合計の割合。",
  },
  {
    attribute: "state",
    "en-US": "State in which the building resides.",
    ja: "建物が存在する都道府県。",
  },
  {
    attribute: "roof_type",
    "en-US": "The type of the building's roof geometry.",
    ja: "建物の屋根の種類。",
  },
  {
    attribute: "roof_material",
    "en-US": "The material of the buildings roof.",
    ja: "建物の屋根の材質。",
  },
  {
    attribute: "roof_condition",
    "en-US": "The condition of the buildings roof.",
    ja: "建物の屋根の状態。",
  },
  {
    attribute: "solar_panels_area",
    "en-US": "The total area of the solar panels that are on the building roof.",
    ja: "建物の屋根上の太陽光パネルの面積。",
  },
  {
    attribute: "skylights",
    "en-US": "The total count of all skylights within a footprint.",
    ja: "敷地内の天窓の総数。",
  },
  {
    attribute: "air_conditioners_area",
    "en-US": "The area of the air conditioners on the building.",
    ja: "建物の屋上と敷地内にあるエアコンの総面積。",
  },
  {
    attribute: "air_conditioners",
    "en-US": "The number of the air conditioners on the building.",
    ja: "建物の屋上と敷地内にあるエアコンユニットの総数。",
  },
  {
    attribute: "rust_area",
    "en-US": "The total area of all rust on the buildings roof.",
    ja: "建物の屋根にある錆の総面積。",
  },
  {
    attribute: "ponding_area",
    "en-US": "The total area of all ponding on the buildings roof.",
    ja: "建物屋上の水たまりの総面積。",
  },
  {
    attribute: "tarp_area",
    "en-US": "The total area of all tarps on the buildings roof.",
    ja: "建物の屋根にある防水シートの総面積。",
  },
  {
    attribute: "pools_count",
    "en-US": "The total count of all pools within a parcel.",
    ja: "敷地内のプールの総数。",
  },
  {
    attribute: "pools_area",
    "en-US": "The total area of all pools within a parcel.",
    ja: "敷地内のプールの総面積。",
  },
  {
    attribute: "temporary_pools_area",
    "en-US": "The total area of all temporary pools within a parcel.",
    ja: "敷地内の仮設プールの総面積。",
  },
  {
    attribute: "trampolines",
    "en-US": "The total count of all trampolines within a parcel.",
    ja: "敷地内のトランポリンの総数。",
  },
  {
    attribute: "number_of_stories",
    "en-US": "The number of stories in the building.",
    ja: "建物の階数。",
  },
  {
    attribute: "distance_to_tree",
    "en-US": "The distance between the nearest edge of a building to the edge of the nearest tree.",
    ja: "建物の最も近い端から最も近い木の端までの距離。",
  },
  {
    attribute: "dis_vegetation",
    "en-US": "The distance between the closest building edge to the closest vegetation edge.",
    ja: "建物の最も近い端から最も近い植生の端までの距離。",
  },
  {
    attribute: "vegetation_zone_1",
    "en-US": "The total percentage of vegetation within a radius of 0 - 5 feet from the building.",
    ja: "建物から半径0〜5m範囲内の植生の総割合。",
  },
  {
    attribute: "vegetation_zone_2",
    "en-US": "The total percentage of vegetation within a radius of 5 - 30 feet from the building.",
    ja: "建物から半径5〜30m範囲内の植生の総割合。",
  },
  {
    attribute: "vegetation_zone_3",
    "en-US": "The total percentage of vegetation within a radius of 30 - 100 feet from the building.",
    ja: "建物から半径30〜100m範囲内の植生の総割合。",
  },
  {
    attribute: "vegetation_zone_4",
    "en-US": "The total percentage of vegetation within a radius of 100 - 150 feet from the building.",
    ja: "建物から半径100〜150m範囲内の植生の総割合。",
  },
  {
    attribute: "tree_zone_1",
    "en-US": "The total percentage of tree within a radius of 0 - 5 feet from the building.",
    ja: "建物から半径0〜5ft範囲内の木の総割合。",
  },
  {
    attribute: "tree_zone_2",
    "en-US": "The total percentage of tree within a radius of 5 - 30 feet from the building.",
    ja: "建物から半径5〜30ft範囲内の木の総割合。",
  },
  {
    attribute: "tree_zone_3",
    "en-US": "The total percentage of tree within a radius of 30 - 100 feet from the building.",
    ja: "建物から半径30〜100ft範囲内の木の総割合。",
  },
  {
    attribute: "tree_zone_4",
    "en-US": "The total percentage of tree within a radius of 100 - 150 feet from the building.",
    ja: "建物から半径100〜150ft範囲内の木の総割。",
  },
  {
    attribute: "shrub_zone_1",
    "en-US": "The total percentage of shrub within a radius of 0 - 5 feet from the building.",
    ja: "建物から半径0〜5ft範囲内の低木の総割合。",
  },
  {
    attribute: "shrub_zone_2",
    "en-US": "The total percentage of shrub within a radius of 5 - 30 feet from the building.",
    ja: "建物から半径5〜30ft範囲内の低木の総割合。",
  },
  {
    attribute: "shrub_zone_3",
    "en-US": "The total percentage of shrub within a radius of 30 - 100 feet from the building.",
    ja: "建物から半径30〜100ft範囲内の低木の総割合。",
  },
  {
    attribute: "shrub_zone_4",
    "en-US": "The total percentage of shrub within a radius of 100 - 150 feet from the building.",
    ja: "建物から半径100〜150ft範囲内の低木の総割合。",
  },
  {
    attribute: "longitude",
    "en-US": "The longitude coordinate in the center of the given building.",
    ja: "指定された建物の中心の経度座標。",
  },
  {
    attribute: "latitude",
    "en-US": "The latitude coordinate in the center of the given building.",
    ja: "指定された建物の中心の緯度座標。",
  },
  {
    attribute: "tree_overhang",
    "en-US": "The percentage of tree that covers the building.",
    ja: "木の枝が建物にかかっている割合。",
  },
  {
    attribute: "ground_height",
    "en-US": "The height of the lowest point on the ground adjacent to the footprint.",
    ja: "延床面積に隣接する地面の最低点の高さ。",
  },
  {
    attribute: "building_height",
    "en-US": "The building height above the ground.",
    ja: "地上からの建物の高さ。",
  },
  {
    attribute: "distance_to_coast",
    "en-US": "Distance between the closest building edge to the closest coast line.",
    ja: "建物の端と海岸上の最も近い点の間の距離。",
  },
  {
    attribute: "square_footage",
    "en-US": "The liveable area within a property.",
    ja: "敷地内の延床面積。",
  },
  {
    attribute: "address",
    "en-US": "Full address of the given building.",
    ja: "指定された建物の完全な住所。",
  },
  {
    attribute: "flat_area",
    "en-US": "The building's roof flat area.",
    ja: "屋根上の平屋根の総面積",
  },
  {
    attribute: "gable_area",
    "en-US": "The sum of the gable walls on the roof.",
    ja: "屋根上の切妻屋根の総面積。",
  },
  {
    attribute: "height_year",
    "en-US": "The year of recency for the height data on a given building.",
    ja: "指定された建物の高さデータの最新の年。",
  },
  {
    attribute: "hip_area",
    "en-US": "The sum of the hipped facets on the roof.",
    ja: "屋根上の寄棟面積の合計。",
  },
  {
    attribute: "parcel_area",
    "en-US": "The area of the given parcel of the building.",
    ja: "指定された建物の敷地面積。",
  },
  {
    attribute: "distance_to_water_body",
    "en-US": "The distance to water.",
    ja: "水域からの距離。",
  },
  {
    attribute: "tree_area",
    "en-US": "The area of the trees.",
    ja: "樹木の面積。",
  },
  {
    attribute: "year_built",
    "en-US": "The year the property was built.",
    ja: "物件の建築年。",
  },
  {
    attribute: "distance_to_road",
    "en-US": "Distance between the closest building edge to the closest road.",
    ja: "建物の最も近い端から最も近い道路の端までの距離。",
  },
  {
    attribute: "distance_to_closest_building",
    "en-US": "Distance between the closest building edge to the closest building.",
    ja: "建物の最も近い端から最も近い建物の端までの距離。",
  },
  {
    attribute: "slope_area",
    "en-US": "The total area of slope.",
    ja: "斜面の総面積。",
  },
  {
    attribute: "building_count",
    "en-US": "The total count of buildings.",
    ja: "建物の総数。",
  },
  {
    attribute: "geom_ID",
    "en-US": "Independent Unique ID provided to each footprint in the database.",
    ja: "データベース内の各敷地に提供されるユニークID。",
  },
  {
    attribute: "business_use",
    "en-US": "The public use of the business.",
    ja: "商業物件の業種。",
  },
  {
    attribute: "air_conditioner_count",
    "en-US": "The number of the air conditioners in the parcel.",
    ja: "建物の屋上と敷地内にあるエアコンユニットの総数。",
  },
  {
    attribute: "gable_length",
    "en-US": "The building's roof gable length.",
    ja: "建物の屋根の切妻の長さ。",
  },
  {
    attribute: "ridge_length",
    "en-US": "The building's roof ridge length.",
    ja: "建物の屋根の棟の長さ。",
  },
  {
    attribute: "floors_number",
    "en-US": "The number of the building's floors. Calculated according to 3 meters per floor.",
    ja: "建物の階数：階ごとに2.8メートルで計算されます。",
  },
  {
    attribute: "building_ground_height",
    "en-US": "The ground height above the sea.",
    ja: "建物が立っている地表の海面からの高さ。",
  },
  {
    attribute: "dis_closest_tree",
    "en-US": "The distance between the closest building edge to the closest tree edge.",
    ja: "建物の最も近い端から木の最も近い端までの距離。",
  },
  {
    attribute: "dis_closest_building",
    "en-US": "The distance between the closest building edge to the closest building.",
    ja: "最も近い建物の端から最も近い建物までの距離。",
  },
  {
    attribute: "dis_coast",
    "en-US": "The distance between the edge of the footprint and the nearest point on the coast.",
    ja: "建物の端と海岸上の最も近い点の間の距離。",
  },
  {
    attribute: "dis_fire_station",
    "en-US": "The distance between the closest building edge to the closest fire station.",
    ja: "最も近い建物の端から最も近い消防署までの距離。",
  },
  {
    attribute: "dis_water_reservoir",
    "en-US": "The distance between the closest building edge to the closest water reservoir.",
    ja: "建物の最も近い端から最も近い貯水池までの距離。",
  },
  {
    attribute: "dis_road",
    "en-US": "The distance between the closest building edge to the closest road.",
    ja: "最も近い建物の端から最も近い道路までの距離。",
  },
  {
    attribute: "solar_panel_ground_area",
    "en-US": "Total area of the solar panels that are on the ground inside the parcel.",
    ja: "敷地内の地面にある太陽光パネルの総面積。",
  },
  {
    attribute: "solar_panel_buildings_area",
    "en-US": "Total area of the solar panels that are on the building's roof inside the parcel.",
    ja: "敷地内の建物の屋根にある太陽光パネルの総面積。",
  },
  {
    attribute: "sports_fields_area",
    "en-US": "Area of the sports fields in the parcel.",
    ja: "敷地内の運動場の面積。",
  },
  {
    attribute: "landform_analysis",
    "en-US": "Analysis of the landforms of the area.",
    ja: "地域の地形。",
  },
  {
    attribute: "elevation_range",
    "en-US": "The mountain elevation range.",
    ja: "山の標高範囲。",
  },
  {
    attribute: "mountains_slope",
    "en-US": "The mountain slope degree.",
    ja: "山の斜面の斜度。",
  },
  {
    attribute: "landslide_type",
    "en-US": "Potential hazards such as the collapse of steep slopes and landslides.",
    ja: "急勾配の崩壊や土砂崩れなどの潜在的な危険。",
  },
  {
    attribute: "landslide_warning_status",
    "en-US": "Information about designated hazard warning areas.",
    ja: "「住民等の生命又は身体に危害が生ずるおそれがある」として警戒区域として指定された地域",
  },
  {
    attribute: "landslide_alert_level",
    "en-US":
      "Areas that have been designated as special caution areas or areas that have not yet been designated as special alert areas but still require attention.",
    ja: "「住民等の生命又は身体に著しい危害が生ずるおそれがある」として特別警戒区域として指定された地域、またはまだ特別警戒区域として指定されていないが、注意が必要な警戒区域",
  },
  {
    attribute: "prior_roof_condition",
    "en-US": "The previous condition of the building's roof.",
    ja: "過去の屋根の状態。",
  },
  {
    attribute: "prior_roof_condition_date",
    "en-US": "The date of the previous condition of the building's roof.",
    ja: "過去の屋根の状態の日付。",
  },
];
