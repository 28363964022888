import styled from "styled-components/macro";

import { baseButtonStyle, COLORS, QUERIES, WEIGHT } from "configs/styles/common";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.LIGHT.White};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "70px")};
  box-shadow: rgb(149 157 165 / 20%) 0 3px 18px;
  position: relative;
  pointer-events: auto;
  z-index: 2;

  @media ${QUERIES.upToTablet} {
    min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "110px")};
  }
`;

export const BackArrowButton = styled.button`
  ${baseButtonStyle};
  grid-area: backButtonArea;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 10px;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: background-color 300ms ease;

  @media ${QUERIES.upToMobile} {
    position: relative;
    height: 44px;
    width: 44px;
    top: revert;
    transform: revert;
    margin-top: 8px;
  }

  &:hover {
    background-color: ${COLORS.ACCENT};
  }
`;

export const BetaLabel = styled.div`
  position: absolute;
  background-color: ${COLORS.LIGHT.White};
  box-shadow: 0 3px 7px rgb(0 0 0 / 11%);
  border-radius: 0 0 8px 8px;
  height: 20px;
  max-width: 160px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  top: 97%;
  left: 50%;
  transform: translateX(-50%);

  @media ${QUERIES.upToMobile} {
    left: 50%;
    max-width: 130px;
  }
`;

export const Text = styled.p`
  color: ${COLORS.DARK.Secondary};
  text-align: center;
  font-weight: ${WEIGHT.regular};
  font-size: 14px;
  line-height: 19px;
`;
