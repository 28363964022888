import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getUser } from "./userAPI";

export function useUser() {
  const {
    data: userData = {},
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.customer],
    queryFn: getUser,
  });

  return { userData, isLoading, isError };
}
