import { formatOptionsData } from "services/helpers/functions";

export const COUNTRIES = [
  {
    label: "US",
    value: "us",
    subdivision: "state",
  },
  { label: "JP", value: "jp", subdivision: "prefecture" },
];

export const stateOptionsAllStates = [
  {
    key: "AL",
    value: "AL",
    text: "Alabama",
  },
  {
    key: "AK",
    value: "AK",
    text: "Alaska",
  },
  {
    key: "AZ",
    value: "AZ",
    text: "Arizona",
  },
  {
    key: "AR",
    value: "AR",
    text: "Arkansas",
  },
  {
    key: "CA",
    value: "CA",
    text: "California",
  },
  {
    key: "CO",
    value: "CO",
    text: "Colorado",
  },
  {
    key: "CT",
    value: "CT",
    text: "Connecticut",
  },
  {
    key: "DE",
    value: "DE",
    text: "Delaware",
  },
  {
    key: "DC",
    value: "DC",
    text: "District Of Columbia",
  },
  {
    key: "FL",
    value: "FL",
    text: "Florida",
  },
  {
    key: "GA",
    value: "GA",
    text: "Georgia",
  },
  {
    key: "HI",
    value: "HI",
    text: "Hawaii",
  },
  {
    key: "ID",
    value: "ID",
    text: "Idaho",
  },
  {
    key: "IL",
    value: "IL",
    text: "Illinois",
  },
  {
    key: "IN",
    value: "IN",
    text: "Indiana",
  },
  {
    key: "IA",
    value: "IA",
    text: "Iowa",
  },
  {
    key: "KS",
    value: "KS",
    text: "Kansas",
  },
  {
    key: "KY",
    value: "KY",
    text: "Kentucky",
  },
  {
    key: "LA",
    value: "LA",
    text: "Louisiana",
  },
  {
    key: "ME",
    value: "ME",
    text: "Maine",
  },
  {
    key: "MD",
    value: "MD",
    text: "Maryland",
  },
  {
    key: "MA",
    value: "MA",
    text: "Massachusetts",
  },
  {
    key: "MI",
    value: "MI",
    text: "Michigan",
  },
  {
    key: "MN",
    value: "MN",
    text: "Minnesota",
  },
  {
    key: "MS",
    value: "MS",
    text: "Mississippi",
  },
  {
    key: "MO",
    value: "MO",
    text: "Missouri",
  },
  {
    key: "MT",
    value: "MT",
    text: "Montana",
  },
  {
    key: "NE",
    value: "NE",
    text: "Nebraska",
  },
  {
    key: "NV",
    value: "NV",
    text: "Nevada",
  },
  {
    key: "NH",
    value: "NH",
    text: "New Hampshire",
  },
  {
    key: "NJ",
    value: "NJ",
    text: "New Jersey",
  },
  {
    key: "NM",
    value: "NM",
    text: "New Mexico",
  },
  {
    key: "NY",
    value: "NY",
    text: "New York",
  },
  {
    key: "NC",
    value: "NC",
    text: "North Carolina",
  },
  {
    key: "ND",
    value: "ND",
    text: "North Dakota",
  },
  {
    key: "OH",
    value: "OH",
    text: "Ohio",
  },
  {
    key: "OK",
    value: "OK",
    text: "Oklahoma",
  },
  {
    key: "OR",
    value: "OR",
    text: "Oregon",
  },
  {
    key: "PA",
    value: "PA",
    text: "Pennsylvania",
  },
  {
    key: "RI",
    value: "RI",
    text: "Rhode Island",
  },
  {
    key: "SC",
    value: "SC",
    text: "South Carolina",
  },
  {
    key: "SD",
    value: "SD",
    text: "South Dakota",
  },
  {
    key: "TN",
    value: "TN",
    text: "Tennessee",
  },
  {
    key: "TX",
    value: "TX",
    text: "Texas",
  },
  {
    key: "UT",
    value: "UT",
    text: "Utah",
  },
  {
    key: "VT",
    value: "VT",
    text: "Vermont",
  },
  {
    key: "VA",
    value: "VA",
    text: "Virginia",
  },
  {
    key: "WA",
    value: "WA",
    text: "Washington",
  },
  {
    key: "WV",
    value: "WV",
    text: "West Virginia",
  },
  {
    key: "WI",
    value: "WI",
    text: "Wisconsin",
  },
  {
    key: "WY",
    value: "WY",
    text: "Wyoming",
  },
];

export const USA_STATES = [
  {
    key: "CA",
    value: "CA",
    text: "California",
  },
  {
    key: "CT",
    value: "CT",
    text: "Connecticut",
  },
  {
    key: "FL",
    value: "FL",
    text: "Florida",
  },
  {
    key: "GA",
    value: "GA",
    text: "Georgia",
  },
  {
    key: "MA",
    value: "MA",
    text: "Massachusetts",
  },
  {
    key: "NJ",
    value: "NJ",
    text: "New Jersey",
  },
  {
    key: "NY",
    value: "NY",
    text: "New York",
  },
  {
    key: "PA",
    value: "PA",
    text: "Pennsylvania",
  },
  {
    key: "RI",
    value: "RI",
    text: "Rhode Island",
  },
  {
    key: "TN",
    value: "TN",
    text: "Tennessee",
  },
  {
    key: "TX",
    value: "TX",
    text: "Texas",
  },
  {
    key: "TN",
    value: "TN",
    text: "Tennessee",
  },

  {
    key: "UT",
    value: "UT",
    text: "Utah",
  },
  {
    key: "WA",
    value: "WA",
    text: "Washington",
  },
];

export const JAPAN_PREFECTURES = [
  { label: "千葉 (Chiba)", value: "千葉" },
  { label: "福岡 (Fukuoka)", value: "福岡" },
  { label: "群馬 (Gunma)", value: "群馬" },
  { label: "広島 (Hiroshima)", value: "広島" },
  { label: "兵庫 (Hyogo)", value: "兵庫" },
  { label: "茨城 (Ibaraki)", value: "茨城" },
  { label: "鹿児島 (Kagoshima)", value: "鹿児島" },
  { label: "神奈川 (Kanagawa)", value: "神奈川" },
  { label: "熊本 (Kumamoto)", value: "熊本" },
  { label: "京都 (Kyoto)", value: "京都" },
  { label: "三重 (Mie)", value: "三重" },
  { label: "宮崎 (Miyazaki)", value: "宮崎" },
  { label: "長崎 (Nagasaki)", value: "長崎" },
  { label: "奈良 (Nara)", value: "奈良" },
  { label: "大分 (Oita)", value: "大分" },
  { label: "岡山 (Okayama)", value: "岡山" },
  { label: "沖縄 (Okinawa)", value: "沖縄" },
  { label: "大阪 (Osaka)", value: "大阪" },
  { label: "佐賀 (Saga)", value: "佐賀" },
  { label: "埼玉 (Saitama)", value: "埼玉" },
  { label: "滋賀 (Shiga)", value: "滋賀" },
  { label: "島根 (Shimane)", value: "島根" },
  { label: "栃木 (Tochigi)", value: "栃木" },
  { label: "東京 (Tokyo)", value: "東京" },
  { label: "鳥取 (Tottori)", value: "鳥取" },
  { label: "和歌山 (Wakayama)", value: "和歌山" },
  { label: "山口 (Yamaguchi)", value: "山口" },
];

const { inputMode, city, address, prefecture, state, subdivision } = {
  inputMode: {
    both: "both",
    onlyAddress: "address",
    onlyCoordinates: "lat-long",
  },
  subdivision: {
    prefecture: "prefecture",
    state: "state",
  },
  city: "city",
  address: "address",
  prefecture: "prefecture",
  state: "state",
  zip: "zip",
};

export const SUBDIVISIONS = {
  us: {
    options: formatOptionsData(USA_STATES),
    subdivision: subdivision.state,
    fields: {
      inputMode: inputMode.both,
      city,
      address,
      state,
    },
  },
  jp: {
    options: JAPAN_PREFECTURES,
    subdivision: subdivision.prefecture,
    fields: {
      inputMode: inputMode.onlyAddress,
      address,
      prefecture,
    },
  },
};
