import styled from "styled-components/macro";

import { LogoIcon } from "assets/images/logo";
import {
  baseButtonStyle,
  BORDER_RADIUS,
  COLORS,
  containerWidth,
  headerHeight,
  mobileContainerStyle,
  QUERIES,
} from "configs/styles/common";

import { Button } from "../../common/Button";

export const HeaderWrapper = styled.header`
  height: ${headerHeight.laptop}px;
  width: 100%;
  background-color: ${COLORS.LIGHT.White};
  position: sticky;
  top: -5px;
  box-shadow: 0 4px 15px rgb(0 0 0 / 5%);
  z-index: 999;

  @media ${QUERIES.upToTablet} {
    position: fixed;
    height: ${headerHeight.tablet}px;
    top: 0;
  }

  @media ${QUERIES.upToMobile} {
    height: ${headerHeight.mobile}px;
  }
`;

export const Header = styled.div`
  height: 100%;
  max-width: ${containerWidth()};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media ${QUERIES.upToMobile} {
    ${mobileContainerStyle}
  }
`;

export const LangWrapper = styled.div``;

export const MainNavigation = styled.nav`
  display: flex;
  align-items: center;
  gap: ${({ smallGap }) => (smallGap ? "20px" : "40px")};
`;

export const HeaderSpace = styled.div`
  width: 100%;
  height: ${headerHeight.laptop}px;

  @media ${QUERIES.upToTablet} {
    height: ${headerHeight.tablet}px;
  }

  @media ${QUERIES.upToMobile} {
    height: ${headerHeight.mobile}px;
  }
`;

export const Logo = styled.div`
  height: 39px;
  width: 113px;
  background-image: url(${LogoIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  @media ${QUERIES.upToTablet} {
    height: 42px;
  }

  @media ${QUERIES.upToMobile} {
    height: 50px;
    width: 90px;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media ${QUERIES.upToMobile} {
    gap: 10px;
  }
`;

export const SwitcherWrapper = styled.div``;

export const GeStartedButton = styled(Button)`
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  padding-inline: 20px;
  font-size: ${({ lang }) => (lang === "ja" ? "15px" : "18px")};

  & span {
    font-weight: 400;
  }

  &:focus {
    outline: none;
  }
`;

export const BurgerButton = styled.button`
  ${baseButtonStyle};
  width: 78px;
  height: 65px;
  color: ${COLORS.DARK.Main};
  background-color: ${COLORS.LIGHT.White};
  box-shadow: 0 4px 14px rgb(0 0 0 / 13%);
  border-radius: 10px;

  &:focus {
    outline: none;
  }

  @media ${QUERIES.upToMobile} {
    border-radius: ${BORDER_RADIUS.XS};
    width: 60px;
    height: 50px;
  }
`;

export const HeaderModal = styled.div`
  font-size: 18px;
  color: ${COLORS.ACCENT};
  cursor: pointer;
  transition: color 0.3s ease-out;
  font-weight: 600;

  &:hover {
    color: ${COLORS.DARK.Secondary};
  }
`;

export const Upgrade = styled(HeaderModal)``;
