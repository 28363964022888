import styled from "styled-components";

import { BORDER_RADIUS, BOX_SHADOW, COLORS, WEIGHT } from "configs/styles/common";

import { textSM } from "../Typography";

export const ToggleSwitchButton = styled.button`
  ${textSM};
  color: ${COLORS.DARK.Secondary};
  font-size: ${({ lang }) => lang === "ja" && "var(--12px)"};
  font-weight: ${WEIGHT.medium};
  width: ${({ width }) => (width ? `${width / 2}px` : "88px")};
  height: 100%;
  border-radius: ${BORDER_RADIUS.XS};
  border: none;
  background-color: transparent;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.3s ease-out;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: ${BOX_SHADOW.REGULAR};
  }
`;

export const ToggleSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  max-width: ${({ width }) => (width ? `${width}px` : "203px")};
  height: ${({ height }) => height};
  background-color: ${COLORS.LIGHT.White};
  box-shadow: ${BOX_SHADOW.REGULAR};
  border-radius: ${BORDER_RADIUS.XS};

  & ${ToggleSwitchButton}[data-mode=${(props) => props.switchMode}] {
    background-color: ${COLORS.ACCENT};
  }
`;
