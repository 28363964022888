import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import RangeItem from "./RangeItem";

const RangeSectionContainer = styled.div`
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 190px;
`;

const RangeSection = ({ inputsState, setState }) => {
  const { t } = useTranslation("addressView");

  const rangeList = [
    { name: "data", question: "Data analysis:" },
    { name: "picture", question: "Images quality:" },
  ];

  return (
    <RangeSectionContainer>
      {rangeList.map(({ name, question }, idx) => (
        <RangeItem key={name + idx} name={name} questText={t(question)} inputsState={inputsState} setState={setState} />
      ))}
    </RangeSectionContainer>
  );
};

export default RangeSection;
