import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ROUTE_PATHS } from "app/providers/router";
import { ReactComponent as ArrowLeft } from "assets/icons/arrowLeft.svg";

import * as Styled from "./styles";

export function SubHeader({ minHeight, children, hasGoBack = false, onGoBack = null, ...rest }) {
  const { t } = useTranslation();

  const history = useHistory();

  function onBackClick() {
    if (onGoBack) {
      onGoBack();
      return;
    }

    if (!history.length) {
      history.push(ROUTE_PATHS.PROJECTS);
    }
    history.goBack();
  }

  return (
    <Styled.Wrapper minHeight={minHeight} {...rest}>
      {hasGoBack && (
        <Styled.BackArrowButton onClick={onBackClick} data-testid="go-back">
          <ArrowLeft />
        </Styled.BackArrowButton>
      )}

      {children}

      <Styled.BetaLabel>
        <Styled.Text>{t("Beta")}</Styled.Text>
      </Styled.BetaLabel>
    </Styled.Wrapper>
  );
}
