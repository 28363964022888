import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import API from "api/apiClient";
import { Button, Modal } from "components/presenters/common";

import createFeedbackSubmitData from "../helpers/createFeedbackSubmitData";
import createRateSubmitData from "../helpers/createRateSubmitData";
import RangeSection from "./RangeSection";
import SuccessMessage from "./SuccessMessage";
import TextArea from "./TextArea";
import { showErrorToast, showWarningToast } from "../../../utils";
import { COLORS } from "../../../../../configs/styles/common";

const PopupWrap = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #fff;
  font-size: 16px;
  width: 100%;
`;

const PopupTitle = styled.h3`
  color: rgb(110 120 152 / 60%);
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
`;

const VerticalContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const TypeButtons = styled.div`
  display: flex;
  gap: 1px;
`;

const TypeButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  overflow-wrap: anywhere;
  max-width: 100/3;
  padding: 12px;
  text-align: center;
  border: 1px solid rgb(110 120 152 / 15%);
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? COLORS.DARK.Main : "#efefef")};
  color: ${(props) => (props.isActive ? "#fff" : COLORS.DARK.Main)};
  transition: all 0.3s;

  &:last-of-type {
    border-radius: 0 15px 15px 0;
  }

  &:first-of-type {
    border-radius: 15px 0 0 15px;
  }

  &:focus {
    outline: none;
  }
`;

const RateContainer = styled.div`
  height: 384px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Popup = ({ close }) => {
  const { t } = useTranslation("addressView");

  const types = {
    general: "general",
    bug: "bug",
    idea: "idea",
  };
  const [activeType, setActiveType] = useState(types.general);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [rateFeedbackState, setRateFeedbackState] = useState({
    data: { value: 0, message: "", category: "Data Analysis" },
    picture: { value: 0, message: "", category: "Images Quality" },
  });

  useEffect(() => {
    if (isSuccessMessageShown) {
      setTimeout(close, 3000);
    }
  }, [isSuccessMessageShown, close]);

  const onTypeButtonClick = (e) => {
    e.preventDefault();
    setActiveType(e.target.name);
  };

  function onSubmit(e) {
    e.preventDefault();

    if (activeType !== types.general && !feedbackText) {
      showWarningToast(t("Enter the text of your feedback, please"));
      return;
    }

    if (activeType === types.general) {
      const isRateEmpty = Object.keys(rateFeedbackState).every(
        (key) => !rateFeedbackState[key].value && !rateFeedbackState[key].message
      );

      if (isRateEmpty) {
        showWarningToast(t("Rate and/or fill feedback fields, please"));
        return;
      }
    }

    const feedbackItems = [
      ...createRateSubmitData(rateFeedbackState),
      ...createFeedbackSubmitData(feedbackText, activeType),
    ];

    API.sendFeedback({
      feedback_type: activeType,
      feedback_items: feedbackItems,
    })
      .then((resp) => {
        if (resp.status === 200) {
          setIsSuccessMessageShown(true);
        }
      })
      .catch(() => showErrorToast(t("Something wrong! Try again later.")));
  }

  return (
    <PopupWrap>
      {!isSuccessMessageShown && (
        <>
          <VerticalContainer>
            <PopupTitle>{t("Feedback Type")} *</PopupTitle>
            <TypeButtons>
              <TypeButton onClick={onTypeButtonClick} name="general" isActive={activeType === types.general}>
                {t("General")}
              </TypeButton>
              <TypeButton onClick={onTypeButtonClick} name="bug" isActive={activeType === types.bug}>
                {t("Bug")}
              </TypeButton>
              <TypeButton onClick={onTypeButtonClick} name="idea" isActive={activeType === types.idea}>
                {t("Idea")}
              </TypeButton>
            </TypeButtons>
          </VerticalContainer>
          <RateContainer>
            {activeType === types.general ? (
              <RangeSection inputsState={rateFeedbackState} setState={setRateFeedbackState} />
            ) : (
              <VerticalContainer>
                <PopupTitle>{t("Feedback Message")} *</PopupTitle>
                <TextArea
                  placeholder={t("Enter your feedback for GeoX")}
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                />
              </VerticalContainer>
            )}
          </RateContainer>
          <Button onClick={onSubmit} variant="contained" style={{ display: "flex", alignItems: "center" }}>
            {t("Send Feedback")}
            <span role="img" aria-label="hand">
              👋
            </span>
          </Button>
        </>
      )}

      <Modal open={isSuccessMessageShown} content={SuccessMessage} close={close} modalWidth="30%" isNoBackground />
    </PopupWrap>
  );
};

export default Popup;
