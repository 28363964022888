import React from "react";
import { useTranslation } from "react-i18next";
import { StarIcon as StarSVG } from "@heroicons/react/20/solid";

import { Tooltip } from "../Tooltip";
import * as Styled from "./style";

export function StarIcon({ isFavorite, onClick, width = "40px", height = "35px" }) {
  const { t } = useTranslation("tooltips");

  return (
    <Tooltip content={<span>{!isFavorite ? t("Add to Favorite") : t("Remove from Favorite")}</span>}>
      <Styled.StarButton onClick={onClick} width={width} height={height} isFavorite={isFavorite}>
        <StarSVG />
      </Styled.StarButton>
    </Tooltip>
  );
}
