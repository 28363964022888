import { useState, useEffect } from "react";

export function useStorage(key, defaultValue, useSession = false) {
  const storage = useSession ? sessionStorage : localStorage;

  const [value, setValue] = useState(() => {
    try {
      const storedValue = storage.getItem(key);
      return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(
        `Error retrieving value for key "${key}" ${useSession ? "sessionStorage" : "localStorage"}:`,
        error
      );
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting value for key "${key}" ${useSession ? "sessionStorage" : "localStorage"}:`, error);
    }
  }, [key, value, storage, useSession]);

  return [value, setValue];
}
