import { useLayoutEffect, useState, useRef } from "react";

import { showErrorToast } from "../../helpers/utils";

export function useError() {
  const [error, setError] = useState();

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    showErrorToast(error);
  }, [error]);

  return [setError];
}
