import React from "react";
import { useHistory } from "react-router-dom";

import * as Styled from "../../style";

export function Logo() {
  const history = useHistory();

  function onLogoClick() {
    history.push("/");
  }
  return <Styled.Logo onClick={onLogoClick} data-testid="logo" />;
}
