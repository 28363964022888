import React, { useState, useMemo, createContext, useContext } from "react";

import { initialFiltersState } from "./helpers";

const FiltersContext = createContext();

export function FiltersProvider({ children }) {
  const [filters, setFilters] = useState(initialFiltersState);
  const value = useMemo(
    () => ({
      filters,
      setFilters,
    }),
    [filters]
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
}

export function useFilters() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error("Usage of Filters context outside of the Provider");
  }

  return context;
}

export const advancedFiltersInterface = {
  roof: {
    roof_material: [],
    roof_type: [],
    roof_condition: [],
    footprint_area: {},
    flat_area: {},
    ridge_length: {},
    gable_length: {},

    roof_additions: {
      solar_panels: null, //boolean
      skylight: null, //boolean
      air_condition: null, //boolean
      chimney: null, //boolean
      tarp: null, //boolean
      ponding: null, //boolean
    },
  },
  building: {
    square_footage: {},
    number_of_stories: {},
    distance_to_coast: {},
    distance_to_water_body: {},
    dis_vegetation: {},
    distance_to_tree: {},
    tree_overhang: {},
    tree_area: {},
    shrub_zone_1: {},
    shrub_zone_2: {},
    shrub_zone_3: {},
    shrub_zone_4: {},
    tree_zone_1: {},
    tree_zone_2: {},
    tree_zone_3: {},
    tree_zone_4: {},
    vegetation_zone_1: {},
    vegetation_zone_2: {},
    vegetation_zone_3: {},
    vegetation_zone_4: {},
    business_name: "",
    business_use: "",
    distance_to_closest_building: {},
    distance_to_road: {},
    distance_water_reservoir: {},
    dis_fire_station: {},
    building_ground_height: {},
    floors_number: {},
    building_height: {},
  },
  parcel: {
    parcel_area: {},
    building_count: {},
    parcel_additions: {
      tank: null, //boolean
      trampoline: null, //boolean
      swimming_pool: null, //boolean
      temporary_pool: null, //boolean
      sports_field: null, //boolean
      solar_panel_ground: null, //boolean
      solar_panel_building: null, //boolean
    },
    footprint_area_parcel: {},
    footprint_over_parcel: {},
  },
};
