import React from "react";
import { Link } from "react-router-dom";

import * as Styled from "./styles";

export function TabNavItem({
  id,
  title,
  activeTab,
  setActiveTab,
  href,
  minWidth = "125px",
  testId = "",
  disabled = false,
}) {
  const onTabItemClick = () => {
    setActiveTab(id);
  };

  return (
    <Styled.TabNavItem
      onClick={!disabled ? onTabItemClick : null}
      active={activeTab === id}
      minWidth={minWidth}
      data-testid={testId}
      disabled={disabled}
    >
      {href ? <Link to={href}>{title}</Link> : <>{title}</>}
    </Styled.TabNavItem>
  );
}
