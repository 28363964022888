import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getUserBasicInfo } from "./userAPI";

export function useUserBasicInfo() {
  const { data: userBasicInfo = {} } = useQuery({
    queryKey: [queryKeys.userBasic],
    queryFn: getUserBasicInfo,
  });

  return { userBasicInfo };
}
