import React, { createContext, useRef, useEffect, useState, useMemo, useContext } from "react";

const GsapContext = createContext();

function getAsyncGsapModule() {
  return Promise.all([import("gsap"), import("gsap/ScrollToPlugin"), import("gsap/ScrollTrigger")]);
}

export function GsapProvider({ children }) {
  const GsapRef = useRef();
  const ScrollToRef = useRef();
  const ScrollTriggerRef = useRef();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getAsyncGsapModule().then(([Gsap, ScrollTo, ScrollTrigger]) => {
      GsapRef.current = Gsap;
      ScrollToRef.current = ScrollTo;
      ScrollTriggerRef.current = ScrollTrigger;
      setIsLoaded(true);
    });
  }, [isLoaded]);

  const value = useMemo(
    () => ({
      isLoaded,
      Gsap: GsapRef.current,
      ScrollTo: ScrollToRef.current,
      ScrollTrigger: ScrollTriggerRef.current,
    }),
    [isLoaded]
  );

  return <GsapContext.Provider value={value}>{children}</GsapContext.Provider>;
}

export function useGsap() {
  return useContext(GsapContext);
}
