import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { ArrowSvg, LineOrientation } from "react-simple-arrows";
import { useTranslation } from "react-i18next";

import * as Styled from "./styles";
import { useOnClickOutside } from "../../../hooks";

const ARROW_START_OFFSET = 10;
const ARROW_END_OFFSET = 14;

export function GuidedTour(props) {
  const {
    targetElement,
    children,
    close,
    guideTitle,
    guideText,
    moveGuide = "",
    orientation = "VERTICAL",
    arrowStart = "top",
    arrowEnd = "bottom",
    notCenterChildren = false,
  } = props;

  const { t, i18n } = useTranslation("tooltips");
  const isJapanese = i18n.language === "ja";
  const guideRef = useRef();

  const [targetRect, setTargetRect] = useState(null);

  useOnClickOutside(guideRef, close);

  useEffect(() => {
    if (!targetElement.style) return;

    setTimeout(() => {
      setTargetRect(targetElement.getBoundingClientRect());
    }, 150);
  }, [targetElement]);

  useEffect(() => {
    function updateRect() {
      setTargetRect(targetElement.getBoundingClientRect());
    }

    document.addEventListener("scroll", updateRect);

    return () => {
      document.removeEventListener("scroll", updateRect);
    };
  }, [targetElement]);

  function getStartCoordinate() {
    const start = { x: 0, y: 0 };

    const guideRect = guideRef?.current?.getBoundingClientRect();

    switch (arrowStart) {
      case "top":
        start.y = guideRect.top - ARROW_START_OFFSET;
        start.x = guideRect.left + guideRect?.width / 2;
        break;
      case "bottom":
        start.y = guideRect?.bottom + ARROW_START_OFFSET;
        start.x = guideRect?.left + guideRect?.width / 2;
        break;
      case "left":
        start.y = guideRect?.top + guideRect?.height / 2;
        start.x = guideRect?.left - ARROW_START_OFFSET;
        break;
      case "right":
        start.y = guideRect?.top + guideRect?.height / 2;
        start.x = guideRect?.right + ARROW_START_OFFSET;
        break;
      default:
        start.y = guideRect?.[arrowStart];
        start.x = guideRect?.left + guideRect?.width / 2;
    }
    return start;
  }

  function getEndCoordinate() {
    const end = { x: 0, y: 0 };

    switch (arrowEnd) {
      case "bottom":
        end.y = targetRect?.top + targetRect?.height + ARROW_END_OFFSET;
        end.x = targetRect?.left + targetRect?.width / 2;
        break;
      case "right":
        end.y = targetRect?.top + targetRect.height / 2;
        end.x = targetRect?.right + ARROW_END_OFFSET;
        break;
      case "top":
        end.y = targetRect?.top - ARROW_END_OFFSET;
        end.x = targetRect?.left + targetRect?.width / 2;
        break;
      case "left":
        end.y = targetRect?.top + targetRect.height / 2;
        end.x = targetRect?.left - ARROW_END_OFFSET;
        break;
      default:
        end.y = targetRect?.top + targetRect?.height + ARROW_END_OFFSET;
        end.x = targetRect?.left + targetRect?.width / 2;
    }
    return end;
  }

  return ReactDOM.createPortal(
    <>
      <Styled.GlobalStyle />
      <Styled.Overlay id="HelperOverlay">
        {targetRect && (
          <ArrowSvg
            strokeWidth="2"
            color="white"
            start={{
              x: getStartCoordinate().x,
              y: getStartCoordinate().y,
            }}
            end={{
              x: getEndCoordinate().x,
              y: getEndCoordinate().y,
            }}
            orientation={LineOrientation[orientation]}
          />
        )}
        <Styled.Content ref={guideRef} moveGuide={moveGuide}>
          <Styled.Title isJapanese={isJapanese}>{t(guideTitle)}</Styled.Title>
          <Styled.Text as="p" isJapanese={isJapanese}>
            {t(guideText)}
          </Styled.Text>
          <Styled.Button onClick={close} isJapanese={isJapanese} data-testid={guideTitle}>
            {t("Got it")}
          </Styled.Button>
        </Styled.Content>

        {targetRect && (
          <Styled.ElementGlow
            top={targetRect.top}
            left={targetRect.left}
            width={targetRect.width}
            height={targetRect.height}
            notCenter={notCenterChildren}
          >
            {children}
          </Styled.ElementGlow>
        )}
      </Styled.Overlay>
    </>,
    document.getElementById("content-root")
  );
}
