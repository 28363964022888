import React from "react";
import ToolTip from "rc-tooltip";
import { useTranslation } from "react-i18next";

import { PLACEMENT_TOOLTIP } from "./consts";

import "rc-tooltip/assets/bootstrap.css";
import "./index.css";

export function Tooltip({ children, content, placement = PLACEMENT_TOOLTIP.TOP, isEmpty = false, id = "" }) {
  const { i18n } = useTranslation();
  const className = i18n.language === "ja" ? "jp" : "";

  return (
    <ToolTip
      id={id}
      placement={placement}
      overlay={content}
      overlayClassName={`${className} ${isEmpty && "empty"}`}
      trigger={["hover", "focus"]}
    >
      {children}
    </ToolTip>
  );
}
