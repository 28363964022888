import basicConfig from "./basicConfig";

const _ = undefined;

const filterAPI = {
  getSavedFilters(project_id) {
    const params = {
      project_id,
    };

    return basicConfig
      .createRequest("/v1/customer/saved-filters", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  addSavedFilters(data) {
    return basicConfig
      .createRequest("/v1/customer/saved-filters", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  updateSavedFilters(filterId, data) {
    return basicConfig
      .createRequest(`/v1/customer/saved-filters/${filterId}`, "PATCH", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  deleteSavedFilter(id) {
    return basicConfig.createRequest(`/v1/customer/saved-filters/${id}`, "DELETE").then((res) => res.data);
  },

  getAddressesFilterOptions(projectId, data) {
    return basicConfig
      .createRequest(
        `/v1/projects/${projectId}/user-addresses/filter-values`,
        "POST",
        { "content-type": "application/json" },
        data
      )
      .then((res) => res.data);
  },

  filterAddresses(projectId, data, payload = null) {
    return basicConfig
      .createRequest(
        `/v1/projects/${projectId}/user-addresses`,
        "POST",
        { "content-type": "application/json" },
        data,
        _,
        payload
      )
      .then((res) => res.data);
  },

  getRoofConditionStatsByZip(project_id, zip) {
    const params = {
      project_id,
      zip,
    };

    return basicConfig
      .createRequest("/v1/projects/zip-wise-search", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },
};

export default filterAPI;
