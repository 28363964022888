import API from "api/apiClient";

import { formatDataToSelects } from "../formatDataToSelects";
import { showErrorToast } from "../../utils";

export function setAddressesFiltersOptions(id, setToLocal, data, isFullDataFiltering) {
  if (isFullDataFiltering) {
    const wrappedData = {
      filters: {
        ...data,
      },
    };

    return API.getAddressProjectDetail(id, wrappedData).then((resp) => {
      if (resp.status === 200) {
        setToLocal(formatDataToSelects(resp.data.filters));
      } else {
        showErrorToast(resp.msg);
      }
    });
  }

  return API.getAddressesFilterOptions(id, data).then((resp) => {
    if (resp.status === 200) {
      setToLocal(formatDataToSelects(resp.data));
    } else {
      showErrorToast(resp.msg);
    }
  });
}
