import React from "react";
import styled from "styled-components/macro";

import { QUERIES } from "configs/styles/common";

export function TabContent({ id, activeTab, children, activeButton, index, streetView = false }) {
  const paginationCheck = activeButton === undefined || index === undefined ? true : activeButton === index;
  return activeTab === id && paginationCheck ? (
    <Content streetView={streetView} data-testid="tab-content">
      {children}
    </Content>
  ) : null;
}

const Content = styled.div`
  height: 100%;
  width: 100%;

  @media ${QUERIES.upToTablet} {
    height: ${({ streetView }) => streetView && "600px"};
  }
`;
