import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import { Loader } from "../../Loader";
import { NotificationItem } from "./NotificationItem/NotificationItem";
import * as Styled from "../ui/style";

export function NotificationList({ notifications, isLoading, setIsVisible }) {
  const { t } = useTranslation();

  useEffect(() => {
    function onClick(e) {
      if (e.target.closest("#box") || e.target.name === "bell") return;
      setIsVisible(false);
    }

    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [setIsVisible]);

  return ReactDOM.createPortal(
    <Styled.ListWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} position="absolute" />
      ) : notifications.length ? (
        notifications.map((notification) => <NotificationItem key={notification.id} notification={notification} />)
      ) : (
        <span>{t("You don't have any notifications")}</span>
      )}
    </Styled.ListWrapper>,
    document.getElementById("upper-content-root")
  );
}
