import React, { forwardRef } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { BORDER_RADIUS, BOX_SHADOW, COLORS, QUERIES } from "configs/styles/common";

import { text } from "../../presenters/common/Typography";

export const TextArea = forwardRef(function TextArea({ testId = "", ...delegated }, ref) {
  const { i18n } = useTranslation();

  return <StyledTextArea data-testid={testId} lang={i18n.language} ref={ref} {...delegated} />;
});

export const StyledTextArea = styled.textarea`
  ${text};
  appearance: none;
  padding: 12px 24px;
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight && maxHeight};
  background-color: ${COLORS.LIGHT.White};
  border-radius: ${BORDER_RADIUS.XS};
  border: none;
  box-shadow: ${BOX_SHADOW.LIGHT};
  resize: none;

  &::placeholder {
    color: ${COLORS.INPUT.Placeholder};
    font-size: ${({ lang }) => (lang === "ja" ? "var(--14px)" : "var(--16px)")};

    @media ${QUERIES.upToMobile} {
      font-size: ${({ lang }) => (lang === "ja" ? "var(--12px)" : "var(--14px)")};
    }
  }

  &:focus {
    outline: 1.5px solid ${COLORS.DARK.Main};
  }

  @media ${QUERIES.upToMobile} {
    height: 70px;
  }
`;
