import styled from "styled-components/macro";
import { css } from "styled-components";

import CheckSVG from "assets/icons/checked.svg";
import { COLORS, FONTS } from "configs/styles/common";

export const CheckBoxLabel = styled.label`
  cursor: pointer;
  font-weight: 500;
  font-family: ${FONTS.Main};
  color: ${COLORS.DARK.Secondary};
  display: grid;
  grid-template-columns: 21px auto;
  gap: 7px;
  align-items: center;
  color: ${({ disabled }) => disabled && COLORS.GREY.Main};
  font-size: 12px;
`;

export const CheckboxInput = styled.input`
  cursor: pointer;
  appearance: none;
  background-color: ${COLORS.GREY.Secondary};
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 5px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  ${(props) =>
    !props.ordinaryStyle &&
    css`
      &:checked {
        background-color: ${COLORS.ACCENT};

        &:checked::before {
          transform: scale(1);
        }
      }
    `}

  ${(props) =>
    props.ordinaryStyle &&
    css`
      &:checked {
        background-color: ${COLORS.INPUT.Success};
        border: 2px solid ${COLORS.INPUT.Success};

        &::before {
          content: "";
          width: 15px;
          height: 9px;
          background-image: url(${CheckSVG});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
        }

        &:checked::before {
          transform: scale(1);
        }
      }
    `}

  &:disabled {
    cursor: default;
    background-color: ${COLORS.GREY.Secondary};
  }
`;
