import Popup from "reactjs-popup";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

import { COLORS } from "configs/styles/common";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

export const MenuPopup = styled(Popup)`
  &-overlay {
    backdrop-filter: blur(2px);
    animation: ${fadeIn} 400ms cubic-bezier(0.14, 0.78, 0.36, 1);
  }

  &-content {
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60%;
    min-width: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-left: 42px;
    animation: ${slideIn} 500ms cubic-bezier(0.14, 0.78, 0.36, 1);

    @media (max-width: 550px) {
      width: 85%;
    }
  }
`;

export const NavigationList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
  padding-top: 15px;
`;

export const NavigationLink = styled(NavLink)`
  color: ${COLORS.DARK.Main};
  font-size: 18px;
  transition: color 200ms;
  font-weight: 500;
  height: 44px;
  min-width: 90px;
  display: inline-block;

  &:hover {
    color: ${COLORS.ACCENT};
    text-decoration: none;
  }
`;
