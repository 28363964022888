import React from "react";
import styled from "styled-components/macro";

import { BORDER_RADIUS, BOX_SHADOW, centerStyle } from "configs/styles/common";

export const List = styled.ul`
  ${centerStyle};
  margin: 0 auto;
  width: 100%;
  box-shadow: ${BOX_SHADOW.REGULAR};
  border-radius: ${BORDER_RADIUS.XS};
`;

export function TabNavList({ children, style = {} }) {
  return <List style={style}>{children}</List>;
}
