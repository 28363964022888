import { stateOptionsAllStates, JAPAN_PREFECTURES } from "../../../constants/LOCATION_OPTIONS";

export function getTooltipForSubdivision(subdivisionValue, country) {
  if (!country) {
    throw new Error("No country was provided");
  }
  switch (country) {
    case "us":
      return stateOptionsAllStates.find((state) => state.value === subdivisionValue)?.text;
    case "jp":
      return JAPAN_PREFECTURES.find((prefecture) => prefecture.value === subdivisionValue)?.label;
    default:
      return stateOptionsAllStates.find((state) => state.value === subdivisionValue)?.text;
  }
}
