import React from "react";

import { Tooltip } from "../../presenters/common";
import * as Styled from "./style";

export function CheckBox({
  onCheckboxChange,
  label,
  checked,
  disabled,
  name,
  tooltipContent = "",
  ordinaryStyle = false,
}) {
  return (
    <Tooltip content={<span>{tooltipContent}</span>} isEmpty={!tooltipContent}>
      <Styled.CheckBoxLabel disabled={disabled}>
        <Styled.CheckboxInput
          type="checkbox"
          onChange={onCheckboxChange}
          checked={checked}
          disabled={disabled}
          ordinaryStyle={ordinaryStyle}
          name={name}
        />
        {label}
      </Styled.CheckBoxLabel>
    </Tooltip>
  );
}
