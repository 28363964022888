import {
  ceo,
  usa_product_analyst,
  software_developer_4,
  software_developer_3,
  software_developer_2,
  hr,
  head_of_product,
  head_of_ai,
  saas_platform_manager,
  release_manager,
  rd_team_lead,
  qa_analyst_2,
  qa_analyst,
  operation_manager,
  business_development_director,
  data_production_manager,
  cto,
  board_advisor,
  ai_researcher,
  software_developer,
  australia_product_analyst,
} from "assets/images/team";

export const TEAM_LEADERS = [
  {
    id: 1,
    name: "Izik Lavy",
    role: {
      "en-US": "CEO",
      ja: "最高経営責任者",
    },
    image: ceo,
    link: "https://www.linkedin.com/in/izik-lavy-043434157/",
  },
  {
    id: 2,
    name: "Eli Lavi",
    role: {
      "en-US": "CTO",
      ja: "最高技術責任者",
    },
    image: cto,
    link: "https://www.linkedin.com/in/eli-lavi-a5231a3/",
  },
];

export const TEAM_MEMBERS = [
  {
    id: 1,
    name: "Ph.D Lior Shabtay",
    role: {
      "en-US": "Head of <br/> AI",
      ja: "AI担当責任者",
    },
    image: head_of_ai,
  },
  {
    id: 2,
    name: "Yuval Mey Raz",
    role: {
      "en-US": "CBDO",
      ja: "最高ビジネス <br/> 開発責任者",
    },
    image: business_development_director,
  },
  {
    id: 3,
    name: "Ph.D Upendra Belhe",
    role: { "en-US": "Board <br/> Advisor", ja: "取締役会 <br/> アドバイザー" },
    image: board_advisor,
  },
  {
    id: 4,
    name: "Nofar Piterman",
    role: {
      "en-US": "R&D Team <br/> Lead",
      ja: "研究開発チーム <br/> リーダー",
    },
    image: rd_team_lead,
  },
  {
    id: 5,
    name: "Shizuka Ito",
    role: {
      "en-US": "Operation (JP) <br/> Manager",
      ja: "オペレーション（JP）<br/> マネージャー",
    },
    image: operation_manager,
  },
  {
    id: 6,
    name: "Yarden Aharon",
    role: {
      "en-US": "HR & Operation <br/> Manager",
      ja: "人事＆オペレーション <br/> マネージャー",
    },
    image: hr,
  },
  {
    id: 7,
    name: "Ella Shaul",
    role: {
      "en-US": "SaaS Platform <br/> Manager",
      ja: "SaaSプラットフォーム <br/> マネージャー",
    },
    image: saas_platform_manager,
  },
  {
    id: 8,
    name: "Jonathan Agassi",
    role: {
      "en-US": "Head of <br/> Product",
      ja: "プロダクト責任者",
    },
    image: head_of_product,
  },
  {
    id: 9,
    name: "Inbal Galli",
    role: {
      "en-US": "Software <br/> Developer",
      ja: "ソフトウェア <br/> 開発者",
    },
    image: software_developer,
  },
  {
    id: 10,
    name: "Shilat Bargig",
    role: {
      "en-US": "Release <br/> Manager",
      ja: "リリースマネージャー",
    },
    image: release_manager,
  },
  {
    id: 11,
    name: "Alon Wander",
    role: {
      "en-US": "AI <br/> Researcher",
      ja: "AI研究者",
    },
    image: ai_researcher,
  },
  {
    id: 12,
    name: "Shiri Hodara",
    role: {
      "en-US": "USA Product <br/> Analyst",
      ja: "アメリカプロダクト <br/> アナリスト",
    },
    image: usa_product_analyst,
  },
  {
    id: 13,
    name: "Ben Dadon",
    role: {
      "en-US": "Software <br/> Developer",
      ja: "ソフトウェア開発者",
    },
    image: software_developer_2,
  },
  {
    id: 14,
    name: "Daniel Hazan",
    role: {
      "en-US": "Software <br/> Developer",
      ja: "ソフトウェア <br/> 開発者",
    },
    image: software_developer_3,
  },
  {
    id: 15,
    name: "Tamar Ivgi",
    role: {
      "en-US": "Software <br/> Developer",
      ja: "ソフトウェア <br/> 開発者",
    },
    image: software_developer_4,
  },
  {
    id: 16,
    name: "Moran Asher",
    role: {
      "en-US": "Australia Product <br/> Analyst",
      ja: "オーストラリアプロダクト <br/> アナリスト",
    },
    image: australia_product_analyst,
  },
  {
    id: 17,
    name: "Neta Weiler",
    role: {
      "en-US": "QA <br/> Analyst",
      ja: "品質保証 <br/>  アナリスト",
    },
    image: qa_analyst,
  },
  {
    id: 18,
    name: "Arad Shmul",
    role: {
      "en-US": "Data Production <br/> Manager",
      ja: "データ製作 <br/> マネージャー",
    },
    image: data_production_manager,
  },
  {
    id: 19,
    name: "Amit Asaf",
    role: {
      "en-US": "Japan Product <br/> Analyst",
      ja: "日本製品 <br/> アナリスト",
    },
    image: qa_analyst_2,
  },
];
