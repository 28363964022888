import styled from "styled-components/macro";

import { COLORS, iconButtonStyle } from "configs/styles/common";

export const SidebarToggleWrapper = styled.button`
  ${iconButtonStyle};
  color: ${COLORS.ACCENT};
  background-color: ${COLORS.LIGHT.White};
  height: 80px;
  width: 26px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: ${({ left }) => `${left}px`};
  transition: translate 400ms;
  translate: ${({ isOpen, left }) => (isOpen ? "0 -50%" : `calc(-${left}px + 36px) -50%`)};

  & svg {
    height: 24px;
    transition: rotate 400ms;
    rotate: ${({ isOpen }) => (isOpen ? 0 : "180deg")};
  }

  &:focus {
    outline-color: ${COLORS.ACCENT};
  }
`;
