import { useState, useEffect } from "react";

import API from "api/apiClient";
import { debounce } from "services/helpers/functions";

export function useAdminLogic(key, apiFunc, filtersKeys = [], setError) {
  const [state, setState] = useState([]);
  const [filtersValues, setFiltersValues] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [page, setPage] = useState({ page: 1 });

  const filteringState = () => {
    setPage({ page: 1 });
  };

  const loadMore = () => {
    setPage({ page: page.page + 1 });
  };

  const search = (value) => {
    setSearchValue(value);
  };

  const debouncedSearch = debounce(search, 700);

  useEffect(() => {
    !initialRender && setPage({ page: 1 });
  }, [searchValue, initialRender]);

  useEffect(() => {
    getFilteredData(searchValue);
    initialRender && setInitialRender(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getFilteredData = (value) => {
    const filters = JSON.parse(window.localStorage.getItem(`${key}filters`));
    const filtersValues = JSON.parse(window.localStorage.getItem(`${key}filtersValues`));

    const data = {
      page_num: page.page,
    };

    setFilters(filtersValues);

    if (filters) {
      data.filters = { ...filters };

      filtersKeys.forEach((filterKey) => {
        if (filterKey.isArray) {
          data.filters[filterKey.value] = filters[filterKey.value] ? filters[filterKey.value].split(", ") : null;
        } else if (filterKey.isBoolean) {
          data.filters[filterKey.value] = filters[filterKey.value]
            ? filters[filterKey.value].split(", ").map((el) => el === "Yes")
            : null;
        } else {
          data.filters[filterKey.value] = filters[filterKey.value]
            ? filters[filterKey.value].split(", ").map((filterKeyMiniValue) => {
                return filtersValues[filterKey.value].find((filter) => filter[filterKey.field] === filterKeyMiniValue)
                  .id;
              })
            : null;
        }
      });
    }

    if (value) {
      data.search_query = value;
    }

    API[apiFunc](data)
      .then((data) => {
        setFiltersValues(data.data.filter_values);
        if (page.page === 1) {
          setState(data.data[key]);
        } else {
          setState([...state, ...data.data[key]]);
        }
      })
      .catch((e) => {
        setError(e.response.data.msg);
      });
  };

  return [state, filters, setFilters, filtersValues, filteringState, loadMore, debouncedSearch];
}
