import Cookies from "js-cookie";

import basicConfig from "./basicConfig";

const authAPI = {
  register(data) {
    return basicConfig
      .createRequest("/register", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  login(email, password) {
    const data = {
      email,
      password,
    };
    return basicConfig.createRequest("/login", "POST", { "content-type": "application/json" }, data).then((res) => {
      if (res.data.status === 200) Cookies.set("isLogin", "true");
      return res.data.status;
    });
  },

  adminLogin(email, password) {
    const data = {
      email,
      password,
    };
    return basicConfig
      .createRequest("/v1/admin/login", "POST", { "content-type": "application/json" }, data)
      .then((res) => {
        if (res.data.status === 200) Cookies.set("isLogin", "true");
        return res.data;
      });
  },

  logout() {
    return basicConfig.createRequest("/auth/logout", "POST", { "content-type": "application/json" }, {}).then((res) => {
      if (res.data.status === 200) Cookies.remove("isLogin");
      return res.data;
    });
  },

  connectToGoogle(auth_code) {
    const data = {
      auth_code,
    };
    return basicConfig
      .createRequest("/connect/google", "POST", { "content-type": "application/json" }, data)
      .then((res) => {
        if (res.data.status === 200) Cookies.set("isLogin", "true");
        return res.data.status;
      });
  },

  connectToLinkedin(auth_code, redirect_uri) {
    const data = {
      auth_code,
      redirect_uri,
      scopes: "r_liteprofile,r_emailaddress",
    };
    return basicConfig
      .createRequest("/connect/linkedin", "POST", { "content-type": "application/json" }, data)
      .then((res) => {
        if (res.data.status === 200) Cookies.set("isLogin", "true");
        return res.data.status;
      });
  },

  adminFilterLoginActivity(payload) {
    return basicConfig
      .createRequest("/v1/admin/login-activity/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },
};

export default authAPI;
