import styled from "styled-components";

export const RangeWrapper = styled.div`
  .input-range__track--background {
    height: 8px;
    background: linear-gradient(
      90deg,
      rgb(76 128 71) 0%,
      rgb(243 205 53) 25%,
      rgb(218 147 64) 50%,
      rgb(174 62 62) 75%,
      rgb(119 95 187) 100%
    );
    transition: all 0.3s;
  }

  .input-range__track--active {
    --active-background: transparent;

    background: var(--active-background);
    height: 8px;
    transition: all 0.3s linear;
    opacity: 1;
  }

  .input-range__slider {
    --thumb-background: #ccc;

    background-color: var(--thumb-background);
    top: -24px;
    border: none;
    position: relative;
    width: 16px;
    height: 16px;
    transition: all 0.3s;
  }

  .input-range__slider::after {
    content: "";
    position: absolute;
    bottom: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 8px 0;
    border-color: var(--thumb-background) transparent transparent transparent;
    transition: all 0.3s;
  }
`;
