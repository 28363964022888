import React from "react";
import styled from "styled-components";

import { YoutubePNG, LinkedinPNG } from "assets/images/shared";

const SocialRow = styled.div`
  display: flex;
`;

const Link = styled.a`
  margin-right: 30px;

  & img {
    height: 28px;
  }
`;

export function SocialPanel() {
  return (
    <SocialRow>
      <Link
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.youtube.com/channel/UCIko_wVDSwU8RWKhJbIHzFg/featured"
      >
        <img src={YoutubePNG} alt="Youtube icon" />
      </Link>
      <Link
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.linkedin.com/company/geox-gis-innovations/mycompany"
      >
        <img src={LinkedinPNG} alt="Linkedin icon" />
      </Link>
    </SocialRow>
  );
}
