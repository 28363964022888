import styled, { css } from "styled-components";

import { baseButtonStyle } from "configs/styles/common";

const textStyle = css`
  font-size: 18px;
  line-height: 30px;
  margin-top: 18px;
  text-align: justify;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 840px;
  padding: 30px;
`;

export const Button = styled.button`
  ${baseButtonStyle};
  background-color: transparent;
  font-size: 17px;
  position: relative;
  width: 80px;

  &::after {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #23242a;
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover::after {
    opacity: 1;
  }
`;

export const Title = styled.h1`
  margin-top: 35px;
  margin-bottom: 30px;
  font-size: 32px;
  text-align: center;
`;

export const Text = styled.p`
  ${textStyle};
  margin-bottom: 18px;
`;

export const SubTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
`;

export const List = styled.ul`
  list-style-type: circle;
`;

export const ListItem = styled.li`
  ${textStyle};
`;

export const Emphasis = styled(Text)`
  font-style: italic;
  text-decoration: underline;
`;

export const OrderedList = styled.ol`
  counter-reset: list-item;

  & ${ListItem} {
    ::marker {
      content: counters(list-item, ".") ".  ";
      counter-increment: list-item;
    }
  }
`;

export const Item = styled.li`
  ${textStyle};
`;
