import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const RouterContext = createContext();

export function RouterProvider({ children }) {
  const location = useLocation();
  const [route, setRoute] = useState({ to: location.pathnames, from: location.pathname });

  useEffect(() => {
    setRoute((prevState) => ({ to: location.pathname, from: prevState.to }));
  }, [location]);

  const memoizedRoute = useMemo(() => ({ route }), [route]);

  return <RouterContext.Provider value={memoizedRoute}>{children}</RouterContext.Provider>;
}

export function useRoute() {
  return useContext(RouterContext);
}
