import React from "react";
import { createPortal } from "react-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

export function ToastsProvider() {
  return createPortal(
    <ToastContainer position="bottom-center" autoClose={1500} />,
    document.querySelector("#content-root")
  );
}
