import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, withRouter } from "react-router-dom";

function BaseRoute({ component: Component, ...rest }) {
  useEffect(() => {
    if (process.env.REACT_APP_GA_ENABLED === "true") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default withRouter(BaseRoute);
