export const COORDINATES_SYSTEM = Object.freeze({
  EPSG_3857: "EPSG:3857",
  EPSG_4326: "EPSG:4326",
});

export function getLatLngByCoordsSystem([lat, lng], coordinatesSystem = COORDINATES_SYSTEM.EPSG_3857) {
  const normalizedLng = coordinatesSystem === COORDINATES_SYSTEM.EPSG_4326 ? lng : (lng * 180) / 20037508.34;
  const y = (lat * 180) / 20037508.34;
  const normalizedLat =
    coordinatesSystem === COORDINATES_SYSTEM.EPSG_4326
      ? lat
      : (Math.atan(Math.pow(Math.E, y * (Math.PI / 180))) * 360) / Math.PI - 90;

  return { lat: normalizedLat, lng: normalizedLng };
}
