import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { useAvatar, useSignOut, useUserBasicInfo } from "api/react-query/userAPI";
import { useBooleanState } from "services/hooks";
import { CollapseBoldSVG } from "assets/images/shared";
import { ReactComponent as LogoutSVG } from "assets/icons/logout.svg";
import { ReactComponent as UserSVG } from "assets/icons/user.svg";
import { ReactComponent as SupportSVG } from "assets/icons/support.svg";
import { Mixpanel } from "services/helpers/common";
import { openTidioChat } from "services/helpers/utils";

import { Avatar } from "../Avatar";
import * as Styled from "./style";

export function MyAccount() {
  const [isDropdownShowing, closeDropdown, openDropdown, toggleDropdown] = useBooleanState(false);
  const navRef = useRef(null);

  const { t } = useTranslation();
  const { userBasicInfo } = useUserBasicInfo();
  const { userAvatar } = useAvatar();
  const { signOut } = useSignOut(t("You are sign out successfully!"));

  function onTidioOpenClick() {
    Mixpanel.track("Click to support from Profile menu");
    openTidioChat();
  }

  return (
    <Styled.Wrapper>
      <Styled.AccountButton onClick={toggleDropdown} data-testid="my-account">
        <Avatar avatar={userAvatar} alt={userBasicInfo.full_name} />
        <Styled.Chevron src={CollapseBoldSVG} alt="open-close dropdown" isDropdownShowing={isDropdownShowing} />
      </Styled.AccountButton>

      <CSSTransition
        in={isDropdownShowing}
        timeout={500}
        classNames="dropdown"
        unmountOnExit
        nodeRef={navRef}
        onEnter={openDropdown}
        onExited={closeDropdown}
      >
        <Styled.Nav ref={navRef}>
          <Styled.AccountList>
            <Styled.ListItem>
              <Styled.User>
                {t("Hi")}, {userBasicInfo.full_name}
              </Styled.User>
            </Styled.ListItem>

            <Styled.ListItem>
              <Styled.IconWrap>
                <UserSVG />
              </Styled.IconWrap>
              <Link to="/settings/profile">{t("My Account")}</Link>
            </Styled.ListItem>

            <Styled.ListItem>
              <Styled.IconWrap>
                <SupportSVG />
              </Styled.IconWrap>
              <Styled.TidioOpenButton onClick={onTidioOpenClick}>{t("Support")}</Styled.TidioOpenButton>
            </Styled.ListItem>

            <Styled.ListItem>
              <Styled.IconWrap>
                <LogoutSVG style={{ width: "22px", marginLeft: "2px" }} />
              </Styled.IconWrap>
              <Styled.Logout onClick={signOut}>{t("Sign Out")}</Styled.Logout>
            </Styled.ListItem>
          </Styled.AccountList>
        </Styled.Nav>
      </CSSTransition>
    </Styled.Wrapper>
  );
}
