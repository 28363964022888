import React from "react";

import { ReactComponent as CloseSVG } from "assets/icons/close_icon_dark.svg";

import * as Styled from "./style";

import "reactjs-popup/dist/index.css";

export function Modal({ content: Content, open, close, modalWidth, ...rest }) {
  return (
    <Styled.StyledPopup
      open={open}
      modal
      closeOnDocumentClick
      closeOnEscape
      nested
      onClose={close}
      lockScroll
      modalWidth={modalWidth}
    >
      <Content close={close} {...rest} />
      <Styled.CloseButton onClick={close} data-testid="close-modal">
        <CloseSVG />
      </Styled.CloseButton>
    </Styled.StyledPopup>
  );
}
