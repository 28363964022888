const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add("visible");
    } else {
      entry.target.classList.remove("visible");
    }
  });
});

export function observe(selector) {
  const boxElList = document.querySelectorAll(selector);
  boxElList.forEach((el) => {
    observer.observe(el);
  });
}

const progressBarObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add("visible");
    }
  });
});

export function observeProgressBar(selector) {
  const boxElList = document.querySelectorAll(selector);
  boxElList.forEach((el) => {
    progressBarObserver.observe(el);
  });
}

export function setPieObserver(node, callback) {
  const pieObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        callback();
      }
    });
  });

  pieObserver.observe(node);
}
