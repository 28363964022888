import React from "react";
import { Link } from "react-router-dom";

import { ROUTE_PATHS } from "app/providers/router";

export function HiddenRoutes() {
  return (
    <div style={{ display: "none" }}>
      <Link to={ROUTE_PATHS.CONTACT_US}>Contact Us</Link>
      <Link to={ROUTE_PATHS.SINGLE_SEARCH}>Single Search</Link>
      <Link to={ROUTE_PATHS.PROJECT}>Projects</Link>
      <Link to="/settings/profile">Settings</Link>
    </div>
  );
}
// this is done for react-snap
