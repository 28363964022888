import basicConfig from "./basicConfig";

const _ = undefined;

const singleSearchAPI = {
  initiateSingleSearch(payload) {
    return basicConfig
      .createRequest("/v1/single-search/initiate", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  deleteParcel(addressId) {
    return basicConfig
      .createRequest(`/v1/parcels/${addressId}/delete`, "DELETE", { "content-type": "application/json" })
      .then((res) => res.data);
  },

  getPointsForPolygons(params) {
    return basicConfig
      .createRequest(
        "/v1/single-search/get-parcels-within-corners",
        "GET",
        { "content-type": "application/json" },
        _,
        _,
        params
      )
      .then((res) => res.data);
  },
};

export default singleSearchAPI;
