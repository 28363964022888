import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CrossSVG } from "assets/images/modals/cross_icon.svg";
import * as Styled from "pages/Private/NotFoundPage/NotFoundPage.style";

import { Button } from "../Button";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  console.log(`%c${error.message}`, "font-weight: 600; font-size: 16px; color: green;");

  const { t } = useTranslation();

  return (
    <Styled.ErrorFallBackPage>
      <Styled.Wrapper>
        <Styled.MessageContainer>
          <Styled.Error>
            <CrossSVG />
          </Styled.Error>
          <div style={{ height: "50px" }} />
          <Styled.Text>{t("Something Went Wrong!")}</Styled.Text>
          <Button onClick={resetErrorBoundary} style={{ maxWidth: "300px", height: "46px", pointerEvents: "auto" }}>
            {t("Try again")}
          </Button>
        </Styled.MessageContainer>
      </Styled.Wrapper>
    </Styled.ErrorFallBackPage>
  );
}
