export const queryKeys = {
  customer: "customer",
  avatar: "avatar",
  userBasic: "user-basic",
  mapMarkers: "map-markers",
  filterAddresses: "filter-addresses",
  addressBuildings: "address-buildings",
  projectStatistic: "project-statistic",
  buildingsMapBoundaries: "buildings-map-boundaries",
  parcelsBoundaries: "parcels-boundaries",
  governmentLayer: "government-layer",
  addressIds: "address-ids",
  createdProjectAddresses: "created-project-addresses",
  tiles: "tiles",
  comparisonProject: "comparison-project-details",
};
