export const ROUTE_PATHS = {
  HOME: "/home",
  ADMIN_LOGIN: "/admin/login",
  SIGN_IN: "/sign-in",
  VERIFY_EMAIL: "/verify-email/:token",
  REQUEST_SIGN_UP: "/request-sign-up",
  SIGN_UP: "/sign-up",
  COOKIE_NOTICE: "/cookie-notice",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  CONTACT_US: "/contact-us",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  UNSUBSCRIBE: "/unsubscribe",
  ABOUT_US: "/about-us",
  PLATFORM: "/platform",
  NEWS_1: "/news/1",
  NEWS_2: "/news/2",
  NEWS_3: "/news/3",
  LINKEDIN: "/linkedin",
  ADMIN: "/admin",
  ADMIN_MANAGE_PLANS: "/admin/manage-plans",
  ADMIN_MANAGE_USERS: "/admin/manage-users",
  ADMIN_MANAGE_COUPONS: "/admin/manage-coupons",
  ADMIN_MANAGE_PROJECTS: "/admin/manage-projects",
  ADMIN_CONTACT_US: "/admin/contact-us",
  ADMIN_FEEDBACK: "/admin/feedback",
  ADMIN_LOGIN_ACTIVITY: "/admin/analytics/login-activity",
  ADMIN_MANAGE_ORDERS: "/admin/manage-orders",
  ADMIN_BILLS: "/admin/bills",
  SINGLE_SEARCH: "/single-search",
  PROJECTS: "/projects",
  PROJECT: "/project/:projectId",
  PROJECT_VIEW_PAGE: "/project-view-page/:projectId/:tab",
  ADDRESS_VIEW_PAGE: "/address-view/:projectId/:addressId/:buildingNum",
  PDF_EXPORT: "/pdf-export/:projectId/:addressId",
  SETTINGS: "/settings/:tab",
  CHANGE_PASSWORD: "/change-password",
};
