export default function createFeedbackSubmitData(stateData, activeTab) {
  return stateData
    ? [
        {
          feedback_message: stateData,
          feedback_rating: 5, //remove when it'll be optional
          feedback_category: activeTab,
        },
      ]
    : [];
}
