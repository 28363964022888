import styled from "styled-components/macro";

import { baseButtonStyle, COLORS, WEIGHT } from "configs/styles/common";

export const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 14px rgba(0 0 0 / 13%);
  background-color: ${COLORS.LIGHT.White};
  height: 38px;
  width: 82px;

  .dropdown-enter {
    opacity: 0;
    transform: translateY(-20%);
  }

  .dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms;
  }

  .dropdown-exit {
    opacity: 1;
  }

  .dropdown-exit-active {
    opacity: 0;
    transform: translateY(-20%);
    transition: all 300ms;
  }
`;

export const AccountButton = styled.button`
  ${baseButtonStyle};
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 4px;
`;

export const Chevron = styled.img`
  width: 12px;
  transform: rotate(180deg);
  transform: ${({ isDropdownShowing }) => isDropdownShowing && "rotate(360deg)"};
  transition: transform 250ms ease-out;
`;

export const Nav = styled.nav`
  width: 160px;
  display: inline-block;
  border-radius: 5px;
  background-color: ${COLORS.LIGHT.White};
  padding-block: 20px;
  padding-right: 20px;
  padding-left: 16px;
  position: relative;
  z-index: 1000;
  box-shadow: 0 4px 14px rgba(0 0 0 / 13%);
  top: 22px;
  right: 64px;

  &::before {
    content: "";
    position: absolute;
    top: -24px;
    right: 30px;
    border: 12px solid transparent;
    border-bottom-color: ${COLORS.LIGHT.White};
    z-index: 1100;
  }
`;

export const AccountList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ListItem = styled.li`
  font-weight: ${WEIGHT.medium};
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  text-align: left;

  & a {
    transition: color 200ms ease-out;
    font-size: 15px;

    &:hover {
      color: ${COLORS.ACCENT};
    }
  }
`;

export const IconWrap = styled.div`
  color: #b2b6bd;
  flex-shrink: 0;
`;

export const Logout = styled.button`
  ${baseButtonStyle};
  width: auto;
  font-weight: ${WEIGHT.medium};
  transition: color 200ms ease-out;
  font-size: 15px;

  &:hover {
    color: ${COLORS.ACCENT};
  }
`;

export const User = styled.div`
  font-weight: ${WEIGHT.semiBold};
  width: 100%;
  font-size: 15px;
  margin-bottom: 3px;
`;

export const TidioOpenButton = styled(Logout)``;
