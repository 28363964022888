import { css } from "styled-components";

export const titleLG = css`
  font-size: var(--26px);
  line-height: 1.3;
  font-weight: 700;
`;

export const title = css`
  font-size: var(--22px);
  line-height: 1.4;
  font-weight: 700;
`;

export const titleSM = css`
  font-size: var(--18px);
  line-height: 1.45;
  font-weight: 600;
`;

export const textLG = css`
  font-size: var(--18px);
  line-height: 1.45;
  font-weight: 400;
`;

export const text = css`
  font-size: var(--16px);
  line-height: 1.5;
  font-weight: 400;
`;

export const textSM = css`
  font-size: var(--14px);
  line-height: 1.5;
  font-weight: 400;
`;
