import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import * as Styled from "./style";

export function SidebarToggle({ left = 346, isSidebarOpen, toggleSidebar }) {
  return (
    <Styled.SidebarToggleWrapper onClick={toggleSidebar} isOpen={isSidebarOpen} left={left}>
      <ChevronLeftIcon />
    </Styled.SidebarToggleWrapper>
  );
}
