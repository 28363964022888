import React from "react";

import { ReactComponent as BurgerIcon } from "assets/icons/menu-burger.svg";
import { useBooleanState, useIsLoggedIn } from "services/hooks";

import { Logo } from "../Logo";
import { LangSwitcher, Notification } from "../../../../common";
import { MobileMenu } from "../MobileMenu";
import * as Styled from "../../style";

export function MobileHeader() {
  const [isMenuOpen, setNotMenuOpen, setIsMenuOpen] = useBooleanState();
  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      <Styled.HeaderSpace />
      <Styled.HeaderWrapper>
        <Styled.Header>
          <Logo />

          <Styled.HeaderBox>
            <Styled.SwitcherWrapper>
              <LangSwitcher />
            </Styled.SwitcherWrapper>

            {isLoggedIn && <Notification />}

            <Styled.BurgerButton onClick={setIsMenuOpen} aria-label="menu">
              <BurgerIcon />
            </Styled.BurgerButton>
          </Styled.HeaderBox>
        </Styled.Header>
      </Styled.HeaderWrapper>

      <MobileMenu open={isMenuOpen} close={setNotMenuOpen} />
    </>
  );
}
