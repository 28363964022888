import { useEffect, useCallback } from "react";

export function useOnClickOutside(ref, onClickOutside) {
  const handleClick = useCallback(
    function handleClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside();
      }
    },
    [onClickOutside, ref]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
}
