import React, { forwardRef } from "react";
import styled from "styled-components";

import { iconButtonStyle } from "configs/styles/common";
import { ReactComponent as Pen } from "assets/icons/pen.svg";

const Wrapper = styled.button`
  ${iconButtonStyle};
`;

export const EditButton = forwardRef(function EditButton({ onClick, testId, ...rest }, ref) {
  return (
    <Wrapper onClick={onClick} ref={ref} type="button" data-testid={testId} {...rest}>
      <Pen />
    </Wrapper>
  );
});
