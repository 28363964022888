import React from "react";

const ACCENT_COLOR = "#ffcd00"

export const dots = (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2" cy="20.293" r="2" transform="rotate(180 2 20.293)" fill={ACCENT_COLOR} />
    <circle cx="2" cy="26.293" r="2" transform="rotate(180 2 26.293)" fill={ACCENT_COLOR} />
    <circle cx="14" cy="14.293" r="2" transform="rotate(180 14 14.293)" fill={ACCENT_COLOR} />
    <circle cx="8" cy="14.293" r="2" transform="rotate(180 8 14.293)" fill={ACCENT_COLOR} />
    <circle cx="8" cy="20.293" r="2" transform="rotate(180 8 20.293)" fill={ACCENT_COLOR} />
    <circle cx="2" cy="14.293" r="2" transform="rotate(180 2 14.293)" fill={ACCENT_COLOR} />
    <circle cx="2" cy="8.29297" r="2" transform="rotate(180 2 8.29297)" fill={ACCENT_COLOR} />
    <circle cx="8" cy="8.29297" r="2" transform="rotate(180 8 8.29297)" fill={ACCENT_COLOR} />
    <circle cx="14" cy="8.29297" r="2" transform="rotate(180 14 8.29297)" fill={ACCENT_COLOR} />
    <circle cx="20" cy="8.29297" r="2" transform="rotate(180 20 8.29297)" fill={ACCENT_COLOR} />
    <circle cx="2" cy="2.29297" r="2" transform="rotate(180 2 2.29297)" fill={ACCENT_COLOR} />
    <circle cx="8" cy="2.29297" r="2" transform="rotate(180 8 2.29297)" fill={ACCENT_COLOR} />
    <circle cx="14" cy="2.29297" r="2" transform="rotate(180 14 2.29297)" fill={ACCENT_COLOR} />
    <circle cx="20" cy="2.29297" r="2" transform="rotate(180 20 2.29297)" fill={ACCENT_COLOR} />
    <circle cx="26" cy="2" r="2" transform="rotate(180 26 2)" fill={ACCENT_COLOR} />
  </svg>
);
