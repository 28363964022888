import { S3 } from "services/constants/apiConstants";

export const ai_researcher = S3 + "assets/team/ai_researcher.webp";
export const australia_product_analyst = S3 + "assets/team/australia_product_analyst.webp";
export const board_advisor = S3 + "assets/team/board_advisor.webp";
export const business_development_director = S3 + "assets/team/business_development_director.webp";
export const cbdo = S3 + "assets/team/cbdo.webp";
export const ceo = S3 + "assets/team/ceo.webp";
export const cto = S3 + "assets/team/cto.webp";
export const data_production_manager = S3 + "assets/team/data_production_manager.webp";
export const head_of_ai = S3 + "assets/team/head_of_ai.webp";
export const head_of_product = S3 + "assets/team/head_of_product.webp";
export const hr = S3 + "assets/team/hr.webp";
export const operation_manager = S3 + "assets/team/operation_manager.webp";
export const qa_analyst = S3 + "assets/team/qa_analyst.webp";
export const qa_analyst_2 = S3 + "assets/team/qa_analyst_2.webp";
export const rd_team_lead = S3 + "assets/team/r&d_team_lead.webp";
export const release_manager = S3 + "assets/team/release_manager.webp";
export const saas_platform_manager = S3 + "assets/team/saas_platform_manager.webp";
export const software_developer = S3 + "assets/team/software_developer.webp";
export const software_developer_2 = S3 + "assets/team/software_developer_2.webp";
export const software_developer_3 = S3 + "assets/team/software_developer_3.webp";
export const software_developer_4 = S3 + "assets/team/software_developer_4.webp";
export const usa_product_analyst = S3 + "assets/team/usa_product_analyst.webp";
