import styled from "styled-components/macro";

import { baseButtonStyle, BORDER_RADIUS, BOX_SHADOW, COLORS, QUERIES } from "configs/styles/common";

export const StarButton = styled.button`
  ${baseButtonStyle};
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${COLORS.LIGHT.White};
  box-shadow: ${BOX_SHADOW.ICON_BUTTON};
  border-radius: ${BORDER_RADIUS.ICON};
  color: ${({ isFavorite }) => (isFavorite ? COLORS.ACCENT : "#c8c8c8")};

  &::after {
    position: absolute;
    content: "";

    --tap-increment: -8px;

    inset: var(--tap-increment);
  }

  & svg {
    height: 18px;
  }

  @media ${QUERIES.upToMobile} {
    & svg {
      height: 16px;
    }
  }
`;
